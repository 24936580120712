import { Tooltip2 } from '@blueprintjs/popover2';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalUserData } from '../../../../../gql/user/local';
import { useQueryParam } from '../../../../../utils/common';
import {
  getNewRow,
  useCurrentLocalPage,
  useEditingContext,
} from '../../editing';
import { ReactComponent as Add } from './add.svg';
import { SeparatorWrapper } from './styles';

type Props = {
  isPage?: boolean;
  rowIndex: number;
  disabled?: boolean;
  structureId: string;
};

export const MAX_CREATED_ROWS = 100;

const RowSeparator: FC<Props> = ({ isPage, rowIndex, disabled }) => {
  const user = getLocalUserData();
  const { t } = useTranslation();
  const isPreview = !!useQueryParam('siteVersionId');
  const { updatePageConfig, isCustomerEditorModeEnabled } = useEditingContext();
  const localPage = useCurrentLocalPage();

  const disableCreation =
    !user ||
    isPreview ||
    disabled ||
    !isCustomerEditorModeEnabled ||
    (localPage?.page?.pageConfig?.layout?.length as number) >= MAX_CREATED_ROWS;

  return (
    <div>
      <SeparatorWrapper className="u-padding-y-10" isPage={isPage}>
        <div className="p-divider__default  border-radius-2" />
        <div className="middle">
          {!disableCreation && (
            <Tooltip2
              content={
                <span className="font-12">{t(`structure.new-row`)}</span>
              }
              placement="top"
              popoverClassName="u-margin-bottom-10"
            >
              <Add
                className="add-icon"
                onClick={() => {
                  const layoutCopy = [
                    ...(localPage?.page?.pageConfig?.layout || []),
                  ];

                  updatePageConfig(
                    {
                      layout: [
                        ...layoutCopy.slice(0, rowIndex),
                        getNewRow(),
                        ...layoutCopy.slice(rowIndex),
                      ],
                    },
                    localPage?.page?.id,
                  );
                }}
              />
            </Tooltip2>
          )}
        </div>
      </SeparatorWrapper>
    </div>
  );
};

export default RowSeparator;
