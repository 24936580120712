import styled from 'styled-components';
import { Classes, Dialog } from '@blueprintjs/core';
import { colors } from '../../../../utils/colors';

export const InsightsDialog = styled(Dialog)`
  width: 90vw;
  height: 90vh;
  padding: 34px 41px 25px;
  background-color: white;
  position: relative;
  margin: 70px 0 !important;

  & > .${Classes.DIALOG_HEADER} {
    background-color: white;
    padding: 0 !important;

    & > h5 {
      margin-bottom: 2px;

      & .title-text {
        font-size: 26px;
        line-height: 36px;
      }
    }

    & > button {
      position: absolute;
      top: 9px;
      right: 9px;
      border-radius: 50%;
      background-color: ${colors.primary0};
      min-height: 25px;
      height: 25px;
      min-width: 25px;
      width: 25px;

      &:hover {
        background-color: ${colors.primary2};
      }

      & > span {
        color: white;

        & > svg {
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  & > .${Classes.DIALOG_BODY} {
    margin: 10px 0 0;
    overflow-y: auto;
  }
`;

export const ModalTitle = styled.span`
  max-width: 50%;
  white-space: normal;
`;
