import { useMemo } from 'react';
import {
  BuiltWidgetConfig,
  BuiltWidgetData,
  CONFIG_ELEMENT_VALUES_TYPE,
  FilterSection,
} from '../../../gql/widget/types';
import { getFormattedValue } from '../../../utils/format';

export interface HoverSeries {
  title: string;
  info: (string | number)[];
  corresponds_to: string | null;

  labels?: string[];
  titles?: string[];
  label?: string;
  hasScenario?: boolean;
  values: string[];
  type: CONFIG_ELEMENT_VALUES_TYPE;
}

export const useHoversFromData = (
  data: BuiltWidgetConfig,
  visibleDataIds?: number[],
  scenariosData?: BuiltWidgetData[],
  scenarios?: FilterSection[],
): HoverSeries[] =>
  useMemo(
    () => [
      ...data.data
        .filter(
          ({ showInHover }, ind) =>
            (visibleDataIds?.includes(ind) ?? true) && showInHover !== false,
        )
        .flatMap(({ title, values, labels, id, type, decimalPlaces }, ind) => {
          const commonOptions = {
            corresponds_to: id || String(ind),
            labels,
            values,
            type,
          };

          const scenarioValues = scenariosData?.[ind]?.values || [];

          const scenarioDefaultLabel = scenarios?.[0]?.defaultLabel;
          const scenarioFilteredLabel = scenarios?.[0]?.filteredLabel;

          return [
            {
              ...commonOptions,
              info: values.map((val) =>
                getFormattedValue(val, type, decimalPlaces),
              ),
              title: scenariosData
                ? `${title} (${scenarioDefaultLabel})`
                : title,
              hasScenario: !!scenariosData,
            },
            ...(scenariosData
              ? [
                  {
                    ...commonOptions,
                    info: scenarioValues.map((val) =>
                      getFormattedValue(val, type, decimalPlaces),
                    ),
                    title: `${title} (${scenarioFilteredLabel})`,
                  },
                ]
              : []),
          ];
        }),
      ...(data.hovers?.map(
        ({ values, labels, title, refersTo, type, decimalPlaces }) => ({
          corresponds_to: refersTo,
          info: values.map((val) =>
            getFormattedValue(val, type, decimalPlaces),
          ),
          title,
          labels,
          values,
          type,
          decimalPlaces,
        }),
      ) || []),
    ],
    [data.data, data.hovers, scenarios, scenariosData, visibleDataIds],
  );

export const useHoverHeaders = (data: BuiltWidgetConfig) =>
  useMemo(() => {
    const domain = data.domains[0];
    const showValue = domain?.showValue;
    const title = domain?.title;
    const domainValues = domain?.values as unknown as string[];

    const titleVal = title ? `${title} ` : '';
    const hideValue = showValue === false;

    return domainValues?.map(
      (val) =>
        `${titleVal}${
          hideValue ? '' : getFormattedValue(val, domain?.valuesType)
        }`,
    );
  }, [data.domains]);
