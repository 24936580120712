import { FC, useCallback, useEffect, useState } from 'react';
import { Classes } from '@blueprintjs/core';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import xlsx from './JSONasXLSX';
import XLSXExport, { XLSXType } from './XLSXExport';
import { colors } from '../../utils/colors';
import { FilterForPrint } from '../Widget';
import { Model } from 'survey-core';
import { ENV_VAR, getEnvVar } from '../../utils/common';
import { ENTITY_TYPES } from '../../gql/types';
import { WIDGET_TYPE } from '../../gql/widget/types';
import { getLocalUserData } from '../../gql/user/local';
import { useEditingContext } from '../../containers/Solutions/Structure/editing';
import { ReactComponent as DownloadIcon } from '../../assets/icons/ui/l/download.svg';
import { IconButton } from '../IconButton';
import { Popover } from '../Popover';
import { DropdownList } from '../DropdownList';
import { useTranslation } from 'react-i18next';
import { getIsEmbedded } from '../../containers/Embeddable/utils';

const defaultPxToMmMultiplier = 0.12;

const PX_TO_MM_MULTIPLIER: Record<any, number> = {
  // [WIDGET_TYPE.TEXT]: 0.24,
  // [WIDGET_TYPE.CHOROPLETH_MAP]: 0.24,
};

type Props = {
  getEl: () => HTMLElement | null;
  onPdfSave?: () => void;
  clearSavePage?: () => void;
  title: string;
  entityType: ENTITY_TYPES | 'FORM';
  show: boolean;
  savePage?: boolean;
  widgetType?: WIDGET_TYPE;
  colNum?: number;
  widgetData?: any;
  filtersForPrint?: FilterForPrint[];
  formSummary?: Record<any, any | null>;
  formConfig?: string;
  survey?: Model;
  visibleData?: Record<string, any>;
  noScenariosData?: any;
};

const onClone = (_: any, el: HTMLElement, pageFilters?: FilterForPrint[]) => {
  const freetextFilters =
    el.querySelectorAll<HTMLDivElement>('.freetext-filter');
  freetextFilters.forEach((ff) => {
    const value = (ff.children[0] as HTMLInputElement).value;
    const input = ff.querySelector<HTMLInputElement>('input');

    if (!value) {
      if (input) {
        input.style.color = '#ababab';
      }
    }

    if (input) {
      input.style.paddingTop = '6px';
    }
  });

  const titles = el.querySelectorAll<HTMLDivElement>('.simple-filter-title');
  titles.forEach((title) => {
    title.style.wordBreak = 'break-all';
  });

  const sliderHandles = el.querySelectorAll<HTMLDivElement>(
    `.${Classes.SLIDER_HANDLE}`,
  );

  sliderHandles.forEach((handle) => {
    handle.style.border = `1px solid ${colors.monochromatic2}`;
    handle.style.backgroundColor = `white !important`;
    handle.style.boxShadow = `none !important`;

    const selectedLabel = handle.querySelector<HTMLSpanElement>(
      `.${Classes.SLIDER_LABEL}`,
    );

    if (selectedLabel) {
      selectedLabel.style.backgroundColor = `rgb(233, 234, 239) !important`;
      selectedLabel.style.boxShadow = `none !important`;
    }
  });

  if (pageFilters?.length) {
    const pageFiltersInfo = document.createElement('div');
    pageFiltersInfo.style.fontSize = '12px';
    pageFiltersInfo.style.color = colors.monochromatic2;
    pageFiltersInfo.style.paddingTop = '5px';
    pageFiltersInfo.style.wordBreak = 'break-all';

    const filterValues = pageFilters.reduce(
      (acc, { title, value }, ind) =>
        acc +
        `${title} = ${value}${pageFilters.length - 1 !== ind ? '; ' : ''}`,
      '',
    );

    pageFiltersInfo.innerText = `Der eingesetzte Seitenfilter gibt die folgende Ansicht wieder: ${filterValues}`;

    const widget = el.querySelector('.widget');
    widget?.appendChild(pageFiltersInfo);
  }

  const editBtns = el.querySelectorAll<HTMLButtonElement>(
    'button.sv-footer__edit-btn',
  );

  editBtns.forEach((btn) => (btn.style.display = 'none'));

  const footer = el.querySelector<HTMLButtonElement>(
    '.sv-footer.sv-body__footer',
  );

  if (footer) {
    footer.style.display = 'none';
  }

  const radioBtns = el.querySelectorAll<SVGElement>(
    'svg.sv-item__svg.sv-radio__svg',
  );

  radioBtns.forEach((el) => {
    const checked = (el?.parentElement?.previousSibling as HTMLInputElement)
      ?.checked;

    const radio = document.createElement('div');
    radio.style.height = '18px';
    radio.style.width = '18px';
    radio.style.border = `2px solid ${colors.monochromatic3}`;
    radio.style.borderRadius = '50%';
    radio.style.position = 'relative';
    radio.style.marginTop = '8px';

    if (checked) {
      const radioBullet = document.createElement('div');
      radioBullet.style.width = '7px';
      radioBullet.style.height = '7px';
      radioBullet.style.backgroundColor = colors.monochromatic3;
      radioBullet.style.borderRadius = '50%';
      radioBullet.style.position = 'absolute';
      radioBullet.style.transform = 'translate(-50%,-50%)';
      radioBullet.style.left = '50%';
      radioBullet.style.top = '50%';

      radio.appendChild(radioBullet);
    }

    el?.parentElement?.replaceChild(radio, el);
  });
};

const PDF_PAGE_WIDTH_SWITCH_THRESHOLD = 1415;

const onPagePdfClone = (width: number) => (_: any, el: HTMLElement) => {
  onClone(_, el);

  const PDF_PAGE_HEIGHT =
    width > PDF_PAGE_WIDTH_SWITCH_THRESHOLD
      ? width * 1.5 -
        Math.ceil(Math.abs(PDF_PAGE_WIDTH_SWITCH_THRESHOLD - width) / 11) -
        8
      : width * 1.5 +
        Math.ceil(Math.abs(PDF_PAGE_WIDTH_SWITCH_THRESHOLD - width) / 11) -
        12;
  let fullHeight = 0;

  const pageHeader = el.querySelector<HTMLDivElement>('.page-heading');

  if (pageHeader) {
    pageHeader.style.width = `${width}px`;

    fullHeight = 40 + pageHeader.offsetHeight;
  }

  const widgets = el.querySelector<HTMLDivElement>('.widgets');

  if (widgets) {
    widgets.style.width = `${width}px`;
  }

  const maps = el.querySelectorAll<HTMLDivElement>('.rsm-svg');

  maps.forEach((map) => {
    map.style.minWidth = '100%';
  });

  const rows = el.querySelectorAll<HTMLDivElement>('.page-row');

  let colsMaxHeightsOnPdfPage = Array(5).fill(fullHeight);

  const PAGE_TOP_PADDING = 20;

  rows.forEach((row) => {
    const cols = row.querySelectorAll<HTMLDivElement>(`.page-column`);

    cols.forEach((col, colInd) => {
      const widgets = col.querySelectorAll<HTMLDivElement>(`.widget-pdf`);

      for (let i = 0; i < widgets.length; i++) {
        const widget = widgets[i];
        const isLast = i === widgets.length - 1;
        const isFirst = !i;

        const height =
          widget.offsetHeight + (isFirst ? 8 : 0) + (isLast ? 88 : 0);

        const newColHeight = colsMaxHeightsOnPdfPage[colInd] + height;

        if (newColHeight > PDF_PAGE_HEIGHT) {
          widget.style.marginTop = `${
            PDF_PAGE_HEIGHT - colsMaxHeightsOnPdfPage[colInd] + PAGE_TOP_PADDING
          }px`;

          colsMaxHeightsOnPdfPage[colInd] = height + PAGE_TOP_PADDING;
        } else {
          colsMaxHeightsOnPdfPage[colInd] = newColHeight;
        }
      }
    });

    fullHeight += row.offsetHeight + 80;
    fullHeight %= PDF_PAGE_HEIGHT;
    colsMaxHeightsOnPdfPage = Array(5).fill(fullHeight);
  });
};

const onFormPdfClone = (width: number) => (_: any, el: HTMLElement) => {
  onClone(_, el);

  const dropdowns = el.querySelectorAll<HTMLDivElement>('.sv-dropdown');

  dropdowns.forEach((dropdown) => {
    dropdown.style.backgroundImage = 'none';
  });

  const PDF_PAGE_HEIGHT =
    width > PDF_PAGE_WIDTH_SWITCH_THRESHOLD
      ? width * 1.5 -
        Math.ceil(Math.abs(PDF_PAGE_WIDTH_SWITCH_THRESHOLD - width) / 11) -
        30
      : width * 1.5 +
        Math.ceil(Math.abs(PDF_PAGE_WIDTH_SWITCH_THRESHOLD - width) / 11) +
        10;

  let fullHeight = 0;

  const pageHeader = el.querySelector<HTMLDivElement>('.page-heading');

  if (pageHeader) {
    pageHeader.style.width = `${width}px`;

    fullHeight = 40 + pageHeader.offsetHeight + 20 + 42 + 32;
  }

  const container = el.querySelector<HTMLDivElement>('.sv-root-modern');

  if (container) {
    container.style.width = `${width}px`;
  }

  const formTitle = el.querySelector<HTMLDivElement>(
    '.sv-container-modern__title',
  );

  if (formTitle) {
    fullHeight += formTitle.offsetHeight;
  }

  const pages = el.querySelectorAll<HTMLButtonElement>(
    '.sv-row:not(:last-child)',
  );

  const pageBreakIndexes: number[] = [];

  pages.forEach((page) => {
    const totalQ = pageBreakIndexes[pageBreakIndexes.length - 1] || 0;

    pageBreakIndexes.push(
      page.querySelectorAll<HTMLDivElement>('.sv-question').length - 1 + totalQ,
    );
  });

  const formRows = el.querySelectorAll<HTMLDivElement>('.sv-question');

  const pageVMargin = 30;

  for (let i = 0; i < formRows.length; i++) {
    const row = formRows[i];
    const height =
      row.offsetHeight + 30 + (pageBreakIndexes.includes(i) ? 40 + 40 : 0);

    const newHeight = fullHeight + height + pageVMargin;

    if (newHeight >= PDF_PAGE_HEIGHT) {
      formRows[i - 1].style.marginBottom = `${
        PDF_PAGE_HEIGHT - fullHeight + pageVMargin
      }px`;
      fullHeight = height + pageVMargin;
    } else {
      fullHeight = newHeight - pageVMargin;
    }
  }
};

const proxySettings = {
  allowTaint: true,
  proxy: `${getEnvVar(ENV_VAR.GRAPHQL_URL)?.replace('graphql', '')}image-proxy`,
};

const WIDGET_HEIGHT_MODIFIER: Record<any, number> = {
  [WIDGET_TYPE.SIMPLE_STAT]: 20,
  [WIDGET_TYPE.CHOROPLETH_MAP]: 30,
};

const WIDGET_PDF_HEIGHT_MODIFIER: Record<any, number> = {
  [WIDGET_TYPE.SIMPLE_STAT]: 14,
};

const SaveBtn: FC<Props> = ({
  getEl,
  title,
  entityType,
  widgetType,
  colNum,
  widgetData,
  filtersForPrint,
  formSummary,
  formConfig,
  survey,
  visibleData,
  onPdfSave,
  savePage,
  clearSavePage,
  noScenariosData,
}) => {
  const [loading, setLoading] = useState(false);
  const spaceForPageFilters = filtersForPrint?.length ? 100 : 0;
  const { t } = useTranslation();

  const getPngDataUrl = useCallback(
    async (offsetModifiers?: { y: number; x: number }) => {
      const el = getEl();

      if (!el) {
        return;
      }

      const heightModifier =
        WIDGET_HEIGHT_MODIFIER[widgetType as WIDGET_TYPE] || -35;

      const graphStandHeight = 75;

      const canvas = await html2canvas(el, {
        width: el.offsetWidth + 40,
        height:
          el.offsetHeight +
          spaceForPageFilters +
          heightModifier +
          graphStandHeight,
        y: el.offsetTop + (offsetModifiers?.y || -15),
        x: el.offsetLeft + (offsetModifiers?.x || -20),
        onclone: (_, el) => onClone(_, el, filtersForPrint),
        ...proxySettings,
      });
      return canvas.toDataURL('image/png');
    },
    [getEl, widgetType, spaceForPageFilters, filtersForPrint],
  );

  const savePNG = useCallback(async () => {
    setLoading(true);
    const dataURL = await getPngDataUrl(
      entityType === 'FORM' ? { y: -50, x: -70 } : undefined,
    );

    if (!dataURL) {
      setLoading(false);

      return;
    }

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'download',
      `${title || widgetData?.name || 'unknown'}.png`,
    );
    const url = dataURL.replace(
      /^data:image\/png/,
      'data:application/octet-stream',
    );
    downloadLink.setAttribute('href', url);
    downloadLink.click();
    setLoading(false);
  }, [entityType, getPngDataUrl, title, widgetData?.name]);

  const savePDF = useCallback(async () => {
    setLoading(true);

    if (entityType === ENTITY_TYPES.WIDGET) {
      const pdf = new JsPDF();
      const pngData = await getPngDataUrl();

      const el = getEl();

      if (!pngData || !el) {
        setLoading(false);

        return;
      }

      const pxToMmMultiplier =
        PX_TO_MM_MULTIPLIER[widgetType as WIDGET_TYPE] ||
        defaultPxToMmMultiplier;

      const imageWidth = el.offsetWidth * pxToMmMultiplier;
      const elH = el.offsetHeight + spaceForPageFilters;

      const imageHeight = elH * (pxToMmMultiplier - 0.01);

      const q = el.offsetWidth / 210;

      const isFullWidth = imageWidth > 210 || colNum === 1;

      const heightModifier =
        WIDGET_PDF_HEIGHT_MODIFIER[widgetType as WIDGET_TYPE] || -60;

      pdf.addImage(
        pngData,
        'PNG',
        0,
        0,
        isFullWidth ? 210 : imageWidth,
        isFullWidth ? (elH + heightModifier) / q : imageHeight,
        'alias',
        'FAST',
      );

      pdf.save(title || widgetData?.name);
      setLoading(false);

      return;
    }

    if (entityType === ENTITY_TYPES.PAGE || entityType === 'FORM') {
      const el = getEl();

      if (!el) {
        setLoading(false);

        return;
      }

      await html2pdf()
        .set({
          html2canvas: {
            width: el.offsetWidth + 80,
            x: el.offsetLeft - 80,
            // height: el.offsetHeight + 80,
            y: el.offsetTop - 80,
            onclone:
              entityType === 'FORM'
                ? onFormPdfClone(el.offsetWidth)
                : onPagePdfClone(el.offsetWidth),
            ...proxySettings,
          },
        })
        .from(el)
        .save(title);

      setLoading(false);

      return;
    }
  }, [
    entityType,
    getPngDataUrl,
    getEl,
    widgetType,
    spaceForPageFilters,
    colNum,
    title,
    widgetData?.name,
  ]);

  const saveXLSX = useCallback(async () => {
    const user = getLocalUserData();
    const _formConfig = formConfig ? JSON.parse(formConfig) : null;

    let result: Record<string, any>;

    if (entityType === 'FORM') {
      const dataTitle = title
        ? { [`${title} - Zusammenfassung`]: '' }
        : { 'Form title': '' };

      result = {
        ...dataTitle,
        Absender:
          `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || '',
        ...formSummary,
      };
    } else if (visibleData?.length) {
      result = {
        ...widgetData,
        data: visibleData.map((index: number) =>
          noScenariosData
            ? {
                ...widgetData.data[index],
                title: `${widgetData.data[index].title} (prognose)`,
              }
            : widgetData.data[index],
        ),
      };
    } else if (noScenariosData) {
      result = {
        ...widgetData,
        data: widgetData.data.map((dataChunk: any) => ({
          ...dataChunk,
          title: `${dataChunk.title} (prognose)`,
        })),
      };
    } else {
      result = widgetData;
    }

    const type = entityType === 'FORM' ? 'form' : widgetType;

    const xlsxExport = new XLSXExport(
      {
        ...result,
        data: [...(noScenariosData?.data || []), ...(result?.data || [])],
      },
      filtersForPrint,
      _formConfig,
    );

    const xlsxWidget: XLSXType | undefined = xlsxExport.getWidget(type, survey);

    if (type && xlsxWidget) {
      const { data, settings } = xlsxWidget;

      xlsx(data, settings);
    }
  }, [
    formConfig,
    entityType,
    title,
    formSummary,
    widgetData,
    widgetType,
    filtersForPrint,
    survey,
    visibleData,
    noScenariosData,
  ]);

  useEffect(() => {
    if (savePage) {
      savePDF();
      clearSavePage?.();
    }
  }, [clearSavePage, savePDF, savePage]);

  const { isCustomerEditorModeEnabled } = useEditingContext();
  const isEmbedded = getIsEmbedded();

  if (isCustomerEditorModeEnabled || isEmbedded) {
    return null;
  }

  const downloadsDropDown = () => {
    const listItems = [];

    listItems.push({
      key: 'Image',
      label: t('common.image'),
      value: 'Image',
    });

    // FIXME? is pdf meaningless in this case?
    if (entityType !== ENTITY_TYPES.WIDGET) {
      listItems.push({
        key: 'PDF',
        label: 'PDF',
        value: 'PDF',
      });
    }

    if (
      widgetType !== WIDGET_TYPE.SIMPLE_STAT &&
      widgetType !== WIDGET_TYPE.TEXT
    ) {
      listItems.push({
        key: 'Excel',
        label: 'Excel',
        value: 'Excel',
      });
    }

    // FIXME the w-36 is of course uncool, need to find a cleaner solution
    return (
      <div className="w-36">
        <DropdownList
          items={listItems}
          onSelect={(key) => {
            switch (key) {
              case 'Image': {
                savePNG();
                break;
              }
              case 'PDF': {
                savePDF();
                break;
              }
              case 'Excel': {
                saveXLSX();
                break;
              }
            }
          }}
        />
      </div>
    );
  };

  return entityType === ENTITY_TYPES.WIDGET || entityType === 'FORM' ? (
    <Popover on="click" content={downloadsDropDown()}>
      <IconButton
        ariaLabel={t('common.download')}
        icon={DownloadIcon}
        dataId="save-entity-widget"
      />
    </Popover>
  ) : (
    <IconButton
      ariaLabel={t('common.download')}
      dataId="save-entity-page"
      icon={DownloadIcon}
      onClick={() => {
        if (onPdfSave) {
          onPdfSave();

          return;
        }

        savePDF();
      }}
    />
  );
};

export default SaveBtn;
