import { CustomerRole } from '../types';

export enum USER_ROLES {
  DATA_ANALYST = 'data_analyst',
  ACCOUNT_MANAGER = 'account_manager',
  PREVIEWER = 'previewer',
  CUSTOMER = 'customer',
  ANALYTICS_ENGINEER = 'analytics_engineer',
}

export enum EMAIL_TEMPLATE {
  LETO = 'leto',
  COLLECT = 'collect',
  GENERAL = 'general',
}

export type User = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: USER_ROLES;
  isPending: boolean;
};

export type GroupMember = {
  _user: User;
  group_role: CustomerRole[];
};

export type UsersGroup = {
  _id: string;
  name: string;
  members: GroupMember[];
};

export type CreateUserInput = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  role: USER_ROLES;
};

export type InviteMultipleUsersInput = {
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  groups: { groupId: string; role: CustomerRole[] }[];
  role?: USER_ROLES;
  emailTemplateType?: EMAIL_TEMPLATE;
};

export type UpdateUserInput = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  role?: USER_ROLES;
};

export type Credentials = {
  email: string;
  password: string;
  captchaText?: string;
};

export type ResetPasswordInput = {
  token: string;
  newPassword: string;
  repeatNewPassword: string;
};

export type InvitedUserInput = {
  firstName: string;
  lastName: string;
  email: string;
};

export type HubspotLog = {
  isRunning: false;
  progress: number;
  total: number;
};

export enum Feature {
  csvUpload = 'csvUpload',
  publish = 'publish',
}
