import React, { FC } from 'react';
import { SliderProps } from '@blueprintjs/core/lib/esm/components/slider/slider';
import { StyledSlider, Title } from './styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { WIDGET_BREAKPOINTS } from '../../utils/common';

type Props = {
  tiltLabels?: boolean;
  disableAutoTilt?: boolean;
  title?: string;
  disableAutoLabels?: boolean;
} & SliderProps;

export const getLabelValues = (
  total: number,
  width: number,
  start = 2,
  step = 4,
) => {
  const numOfLabels = Object.values(WIDGET_BREAKPOINTS).reduce(
    (acc, bp) => (width > bp ? acc + step : acc),
    start,
  );
  const stepSize = total / (numOfLabels - 1);

  return [
    0,
    ...Array(numOfLabels - 2)
      .fill(null)
      .map((_, ind) => Math.round((ind + 1) * stepSize))
      .filter((n) => n > 0 && n < total),
    total,
  ];
};

const Slider: FC<Props> = ({
  tiltLabels,
  title,
  disableAutoTilt,
  disableAutoLabels,
  ...props
}) => {
  return (
    <AutoSizer disableHeight style={{ width: '100%' }}>
      {({ width }: { width: number }) => (
        <div>
          {title && <Title>{title}</Title>}
          <StyledSlider
            first={props.value === props.min}
            last={props.value === props.max}
            labelValues={
              disableAutoLabels
                ? undefined
                : getLabelValues(props.max || 10, width)
            }
            tiltLabels={
              disableAutoTilt
                ? tiltLabels
                : width < WIDGET_BREAKPOINTS.SM || tiltLabels
            }
            {...props}
          />
        </div>
      )}
    </AutoSizer>
  );
};

export default Slider;
