import React, { FC } from 'react';
import VenusSimpleStat from './tab_row';
import {
  OnFilterChange,
  SimpleStatChartConfig,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { FilterSelection } from '../../../SimpleFilter';
import { useFilters } from '../../../../utils/filters';
import { getFormattedValue } from '../../../../utils/format';

type Props = {
  data: SimpleStatChartConfig;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
} & WidgetCommonProps;

const SimpleStatChart: FC<Props> = ({
  data,
  onFilterChange,
  filtersVal,
  ...rest
}) => {
  const filters = useFilters(data.widgetFilters);

  return (
    <VenusSimpleStat
      widget={{
        header: data.title,
        data: data.data.map(
          ({
            title,
            info,
            number,
            numberType,
            diff,
            diffColor,
            diffIndicator,
            id,
            decimalPlaces,
          }) => ({
            id,
            name: title || '',
            type: 'T',
            info,
            number: getFormattedValue(number, numberType, decimalPlaces),
            diff,
            diff_color: diffColor,
            indicator: diffIndicator,
          }),
        ),
        filters,
        filtersVal,
        columns: data.data.length,
        info: data.info,
        stand: data.stand,
      }}
      onFilterChange={onFilterChange}
      {...rest}
    />
  );
};

export default SimpleStatChart;
