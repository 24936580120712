import React, { FC, LazyExoticComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ReactComponent as AdminIcon } from '../assets/icons/nav-icons/admin.svg';
import { ReactComponent as ArchiveIcon } from '../assets/icons/nav-icons/archive.svg';
import { ReactComponent as QueryIcon } from '../assets/icons/nav-icons/dataset.svg';
import { ReactComponent as FormIcon } from '../assets/icons/nav-icons/form.svg';
import { ReactComponent as LibraryIcon } from '../assets/icons/nav-icons/library.svg';
import { ReactComponent as SchedulerIcon } from '../assets/icons/nav-icons/scheduler.svg';
import { ReactComponent as SolutionIcon } from '../assets/icons/nav-icons/solution.svg';
import { ReactComponent as WidgetIcon } from '../assets/icons/nav-icons/widget.svg';
import { ReactComponent as ExplorerSidebarIcon } from '../assets/icons/sidebar/standard/explorer.svg';
import { ReactComponent as UploadSidebarIcon } from '../assets/icons/sidebar/standard/upload.svg';
import { LazyLoading } from '../components/LazyLoading';
import { EDIT_PATH, NEW_ITEM_ID_URL } from '../constants/entities';
import { useCurrentUserQuery } from '../gql/user/hooks';
import {
  getIsAnalyticsEngineer,
  getLocalUserData,
  isAuth,
} from '../gql/user/local';
import { Feature, USER_ROLES, User } from '../gql/user/types';
import { useLogout } from '../utils/hooks/useLogout';
import { LANG } from './i18n';
import { getIsEmbedded } from '../containers/Embeddable/utils';
import { getIsPublicRoute } from './utils';

export type IRoute = {
  path: string;
  icon?: FC;
  label?: string;
  container:
    | (() => React.JSX.Element | null)
    | LazyExoticComponent<() => React.JSX.Element | null>;
};

export type SolutionParams = {
  structureUrl: string;
  pageUrl: string;
};

export type EmbeddedWidgetParams = {
  structureId: string;
  widgetId: string;
};

export const INITIAL_LOCATION = 'INITIAL_LOCATION';
export const FORMS_ARCHIVE_URL = 'archive';
export const FORMS_URL = 'forms';
export const INVITE_URL = 'invite';
export const LOGIN_ERROR_PATH = '/access-denied';
export const PUBLIC_PAGE_PREFIX = 'app';
export const EMBED_PREFIX = 'embed';

export const EmbedRoute: IRoute = {
  path: `/${EMBED_PREFIX}/widget/:structureId/:widgetId`,
  label: 'Widget',
  container: React.lazy(() => import('../containers/Embeddable/Widget')),
};

export const AUTH_ROUTES: IRoute[] = [
  {
    path: '/login',
    container: React.lazy(() => import('../containers/Login')),
  },
  {
    path: '/logout',
    container: React.lazy(() => import('../containers/Logout')),
  },
  {
    path: '/forgot-password',
    container: React.lazy(() => import('../containers/ForgotPassword')),
  },
  {
    path: '/reset-password/:token',
    container: React.lazy(() => import('../containers/ResetPassword')),
  },
  {
    path: '/user-invite/:token',
    container: React.lazy(() => import('../containers/UserRegister')),
  },
  {
    path: `/${PUBLIC_PAGE_PREFIX}/:structureId?/:pageId?`,
    container: React.lazy(() => import('../containers/PublicSolution')),
  },
  EmbedRoute,
];

export const SolutionsRoute: IRoute = {
  path: '/solutions',
  label: 'solutions',
  icon: SolutionIcon,
  container: React.lazy(() => import('../containers/Solutions')),
};

export const FormsRoute: IRoute = {
  path: '/forms',
  label: 'forms',
  icon: FormIcon,
  container: React.lazy(() => import('../containers/Forms')),
};

export const SchedulersRoute: IRoute = {
  path: '/schedulers',
  label: 'schedulers',
  icon: SchedulerIcon,
  container: React.lazy(() => import('../containers/Schedulers')),
};

export const WidgetsRoute: IRoute = {
  path: '/widgets',
  label: 'widgets',
  icon: WidgetIcon,
  container: React.lazy(() => import('../containers/Widgets')),
};

export const QueriesRoute: IRoute = {
  path: '/queries',
  label: 'queries',
  icon: QueryIcon,
  container: React.lazy(() => import('../containers/Queries')),
};

export const LibrariesRoute: IRoute = {
  path: '/libraries',
  label: 'libraries',
  icon: LibraryIcon,
  container: React.lazy(() => import('../containers/Libraries')),
};

export const DatasourcesRoute: IRoute = {
  path: '/datasources',
  label: 'datasources',
  container: React.lazy(() => import('../containers/DataSources')),
};

export const SolutionPreviewsRoute: IRoute = {
  path: '/sites',
  label: 'solution-previews',
  container: React.lazy(() => import('../containers/Sites')),
};

export const PagesRoute: IRoute = {
  path: '/pages',
  label: 'pages',
  container: React.lazy(() => import('../containers/Pages')),
};

export const AdminRoute: IRoute = {
  path: '/admin',
  label: 'admin-console',
  icon: AdminIcon,
  container: React.lazy(
    () => import('../containers/AdminConsole'),
  ) as IRoute['container'],
};

export const OrganizationsRoute: IRoute = {
  path: '/admin/organizations',
  label: 'Organizations',
  container: React.lazy(() => import('../containers/Organizations')),
};

export const PlansRoute: IRoute = {
  path: '/admin/plans',
  label: 'Plans',
  container: React.lazy(() => import('../containers/Plans')),
};

export const UsersRoute: IRoute = {
  path: '/admin/users',
  label: 'Users',
  container: React.lazy(() => import('../containers/Users')),
};

export const UserGroupsRoute: IRoute = {
  path: '/admin/user-groups',
  label: 'User Groups',
  container: React.lazy(() => import('../containers/UserGroups')),
};

export const ArchiveRoute: IRoute = {
  path: '/archive',
  label: 'archive',
  icon: ArchiveIcon,
  container: React.lazy(() => import('../containers/Archive')),
};

export const DatasetRoute: IRoute = {
  path: '/dataset/:owner',
  label: 'dataset',
  icon: UploadSidebarIcon,
  container: React.lazy(() => import('../containers/Datasets')),
};

export const ExplorerRoute: IRoute = {
  path: `${QueriesRoute.path}/${NEW_ITEM_ID_URL}`,
  label: 'explorer',
  icon: ExplorerSidebarIcon,
  container: React.lazy(() => import('../containers/Explorer')),
};

export const ROUTES: Array<IRoute> = [
  SolutionsRoute,
  WidgetsRoute,
  QueriesRoute,
  LibrariesRoute,
  FormsRoute,
  ArchiveRoute,
  // more
  DatasourcesRoute,
  SolutionPreviewsRoute,
  PagesRoute,
  EmbedRoute,

  // bottom
  AdminRoute,
  SchedulersRoute,
];

export const ROUTES_ROLES_MAP = {
  [USER_ROLES.DATA_ANALYST]: ROUTES,
  [USER_ROLES.ACCOUNT_MANAGER]: ROUTES,
  [USER_ROLES.PREVIEWER]: ROUTES,
  [USER_ROLES.CUSTOMER]: ROUTES,
  [USER_ROLES.ANALYTICS_ENGINEER]: ROUTES,
};

const NESTED_ROUTES: IRoute[] = [
  {
    path: `${SolutionsRoute.path}/${EDIT_PATH}/:structureUrl/:pageUrl`,
    container: React.lazy(
      () => import('../containers/Solutions/StructureEditor'),
    ),
  },
  {
    path: `${SolutionsRoute.path}/${EDIT_PATH}/:structureUrl`,
    container: React.lazy(
      () => import('../containers/Solutions/StructureEditor'),
    ),
  },
  {
    path: `${SolutionsRoute.path}/:structureUrl/:pageUrl`,
    container: React.lazy(() => import('../containers/Solutions/Structure')),
  },
  {
    path: `${SolutionsRoute.path}/:structureUrl`,
    container: React.lazy(() => import('../containers/Solutions/Structure')),
  },
  OrganizationsRoute,
  PlansRoute,
  {
    path: '/admin/organizations/:id',
    container: React.lazy(
      () => import('../containers/Organizations/OrganizationEdit'),
    ),
  },
  {
    path: '/admin/plans/:id',
    container: React.lazy(() => import('../containers/Plans/PlanEdit')),
  },
  UsersRoute,
  UserGroupsRoute,
  {
    path: '/query/:id',
    container: React.lazy(() => import('../containers/Query')),
  },
  {
    path: '/queries/:id',
    container: React.lazy(() => import('../containers/Explorer')),
  },
  {
    path: `/libraries/${EDIT_PATH}/:id`,
    container: React.lazy(() => import('../containers/Libraries/LibraryEdit')),
  },
  {
    path: `/libraries/:id`,
    container: React.lazy(
      () => import('../containers/Libraries/InsightsLibrary'),
    ),
  },
  {
    path: '/widgets/:versionId',
    container: React.lazy(() => import('../containers/Widgets/Widget')),
  },
  {
    path: '/datasources/:id',
    container: React.lazy(
      () => import('../containers/DataSources/DataSourceEdit'),
    ),
  },
  {
    path: '/pages/:pageUrl',
    container: React.lazy(() => import('../containers/Pages/PageEdit')) as any,
  },
  {
    path: '/structures/:id',
    container: React.lazy(
      () => import('../containers/Structures/StructureEdit'),
    ),
  },
  {
    path: '/sites/:id',
    container: React.lazy(() => import('../containers/Sites/SiteEdit')),
  },
  {
    path: '/forms/:id/:formVersionId?',
    container: React.lazy(
      () => import('../containers/Forms/FormEdit/FormPage'),
    ),
  },
  {
    path: '/schedulers/:id',
    container: React.lazy(
      () => import('../containers/Schedulers/SchedulerEdit'),
    ),
  },
  {
    path: '/admin/users/invite',
    container: React.lazy(() => import('../containers/InviteUsers')),
  },
  {
    path: '/admin/users/:id',
    container: React.lazy(() => import('../containers/Users/UserEdit')),
  },
  {
    path: '/admin/user-groups/:id',
    container: React.lazy(
      () => import('../containers/UserGroups/UserGroupEdit'),
    ),
  },
  {
    path: LOGIN_ERROR_PATH,
    container: React.lazy(() => import('../containers/LoginErrorPage')),
  },
];

export const NESTED_ROUTES_ROLES_MAP = {
  [USER_ROLES.DATA_ANALYST]: NESTED_ROUTES,
  [USER_ROLES.ACCOUNT_MANAGER]: NESTED_ROUTES,
  [USER_ROLES.PREVIEWER]: NESTED_ROUTES,
  [USER_ROLES.CUSTOMER]: NESTED_ROUTES,
  [USER_ROLES.ANALYTICS_ENGINEER]: NESTED_ROUTES,
};

const mapRoutes = (routes: IRoute[]) => {
  return routes.map(({ path, container: Container }) => (
    <Route path={path} key={path} exact>
      <Container />
    </Route>
  ));
};

export const useIsAuthRoute = () => {
  const pathname = useLocation().pathname;

  return AUTH_ROUTES.some(
    ({ path }) =>
      pathname.substr(1).split('/')[0] === path.substr(1).split('/')[0],
  );
};

export const useIsEmbeddedRoute = () => {
  const pathname = useLocation().pathname;

  return pathname.includes(`/${EMBED_PREFIX}/`);
};

export const useIsLogout = () => {
  const pathname = useLocation().pathname;

  return pathname.substring(1).split('/')[0] === 'logout';
};

const Routes = () => {
  const { i18n } = useTranslation();
  const pathname = useLocation().pathname;
  const logout = useLogout();
  const isLogoutRoute = useIsLogout();
  const isAuthRoute = useIsAuthRoute();
  const isEmbedded = getIsEmbedded();
  const isPublicPage = getIsPublicRoute();

  useEffect(() => {
    if (!isAuthRoute) {
      localStorage.setItem(INITIAL_LOCATION, pathname);
    }
  }, [isAuthRoute, pathname]);

  const {
    user,
    refetchCurrentUser,
    features: userFeatures,
    loadingUser,
  } = useCurrentUserQuery();

  const isPermitted = isAuth();

  const role = (user as User)?.role || getLocalUserData()?.role;

  useEffect(() => {
    i18n.changeLanguage(
      role === USER_ROLES.CUSTOMER || isEmbedded || isPublicPage
        ? LANG.DE
        : LANG.EN,
    );
  }, [i18n, role, isEmbedded, isPublicPage]);

  useEffect(() => {
    if (!isAuthRoute && pathname !== '/' && !user) {
      try {
        refetchCurrentUser();
      } catch (e) {
        logout();
      }
    }
  }, [isAuthRoute, logout, pathname, refetchCurrentUser, user]);

  useEffect(() => {
    const hasCustomerCsvUpload =
      role === USER_ROLES.CUSTOMER && userFeatures?.includes(Feature.csvUpload);
    const isAE = getIsAnalyticsEngineer();

    if (
      !loadingUser &&
      (hasCustomerCsvUpload || isAE) &&
      !ROUTES_ROLES_MAP[USER_ROLES.CUSTOMER].includes(DatasetRoute)
    ) {
      ROUTES_ROLES_MAP[USER_ROLES.CUSTOMER].push(DatasetRoute);
    }
  }, [loadingUser, role, userFeatures]);

  return (
    <React.Suspense fallback={<LazyLoading />}>
      {isPermitted && !isLogoutRoute ? (
        <Switch>
          {mapRoutes(ROUTES_ROLES_MAP[role])}
          {mapRoutes(NESTED_ROUTES_ROLES_MAP[role])}
          <Redirect from="/explorer/queries" to="/queries" />
          <Redirect from="/explorer/queries/:id" to="/queries/:id" />
          <Redirect from="*" to={ROUTES_ROLES_MAP[role][0].path} />
        </Switch>
      ) : (
        <Switch>
          {mapRoutes(AUTH_ROUTES)}
          <Redirect from="*" to={AUTH_ROUTES[0].path} />
        </Switch>
      )}
    </React.Suspense>
  );
};

export default Routes;
