import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Line } from '@nivo/line';
import { useHoversFromData, useHoverHeaders } from '../../utils/hovers';
import { useSeparators } from '../../utils/separators';
import { ResizeSensor2 } from '@blueprintjs/popover2';
import {
  getSeriesRanges,
  TRANSLUCENT_OPACITY,
} from '../../utils/dataHighlights';
import PointSymbol from './PointSymbol';

import {
  BuiltWidgetConfig,
  Highlight,
  HIGHLIGHT_TYPE,
  OnFilterChange,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { useFilters } from '../../../../utils/filters';
import SimpleFilter, { FilterSelection } from '../../../SimpleFilter';
import { SETTINGS } from '../../../SimpleFilter/WidgetSettings';
import usePalette, { UNSELECTED_COLOR } from '../../utils/usePalette';
import useFormatGraphSeries from '../../utils/useFormatGraphSeries/useFormatGraphSeries';
import useScale from '../../utils/useScale';
import { WIDGET_BREAKPOINTS } from '../../../../utils/common';
import GraphLegend from '../../components/GraphLegend';
import GraphStand from '../../../GraphStand';
import { getFormattedValue } from '../../../../utils/format';
import { NIVO_THEME } from '../../utils/nivoTheme';
import { getLabelValues } from '../../../Slider';
import { colors } from '../../../../utils/colors';
import DomainHighlight from '../../utils/layers/DomainHighlight';
import LineHighlight from '../../utils/layers/LineHighlight';
import CrossOverContent from '../../components/CrossOverContent';
import AggregationFooter from '../../components/AggregationFooter';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import { getIsDA } from '../../../../gql/user/local';
import { useTranslation } from 'react-i18next';
import { generateUniqueTicks } from '../../utils';
import { getIsEmbedded } from '../../../../containers/Embeddable/utils';

export const Y_AXIS_TICK_SIZE = 20;
export const Y_AXIS_TICK_PADDING = 10;
export const Y_AXIS_TITLE_PADDING = 13;
export const Y_AXIS_TITLE = 10 + Y_AXIS_TITLE_PADDING;

export const X_AXIS_TICK_PADDING = 20;
export const X_AXIS_TITLE_PADDING = 15;
export const X_AXIS_TITLE = 10 + X_AXIS_TITLE_PADDING;

const transformNoHighlightSeries = (s: any[]) =>
  s.map((d) => ({
    ...d,
    id: `${d.id}-noHighlight`,
  }));

const transformHighlightSeries = (
  s: any[],
  highlight: Highlight,
  hTypeInd: number,
) =>
  s.map((d) => ({
    ...d,
    highlight,
    data: d.data.map((d: any) => ({
      ...d,
      translucent:
        d.translucent || highlight.type === HIGHLIGHT_TYPE.TRANSLUCENT,
    })),
    id: `${d.id}-${hTypeInd}`,
  }));

type Props = {
  data: BuiltWidgetConfig;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
} & WidgetCommonProps;

const LineChart: FC<Props> = ({
  data: propsData,
  onFilterChange,
  filtersVal,
  afterTitle,
  settings,
  settingsVal,
  setSettingsVal,
  noScenariosData,
  editProps,
  handleDelete,
  handleReload,
  isEditing,
}) => {
  const [width, setWidth] = useState(0);

  const data = useMemo(
    () => ({
      ...propsData,
      data: noScenariosData || propsData?.data,
    }),
    [noScenariosData, propsData],
  );
  const isScenarios = !!noScenariosData;
  const isDA = getIsDA();
  const { t } = useTranslation();
  const filters = useFilters(data.widgetFilters);
  const separators = useSeparators(data);
  const hoverHeaders = useHoverHeaders(data);
  const isEmbedded = getIsEmbedded();

  const xAxis = data.domains.find(({ type }) => type === 'xAxis');
  const yAxis = data.domains.find(({ type }) => type === 'yAxis');

  const xAxisVals = useMemo(
    () => xAxis?.values?.filter(Boolean) || [],
    [xAxis?.values],
  );

  const originalDataSeries = useMemo(
    () =>
      data.data.map(({ values, id, ...rest }, ind) => ({
        id: id || String(ind),
        data: values.map((val, i) => ({
          x: xAxisVals[i],
          y: val,
        })),
        values,
        ...rest,
      })),
    [data.data, xAxisVals],
  );

  const hoverSeries = useHoversFromData(
    data,
    settingsVal?.[SETTINGS.VISIBLE_DATA],
    isScenarios ? propsData?.data : undefined,
    data?.filterSections,
  );

  const [selectedData, setSelectedData] = useState<number | null>(null);
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);

  const originalPalette = usePalette(
    originalDataSeries,
    selectedData,
  ) as string[];

  const palette = useMemo(
    () =>
      originalPalette.filter(
        (_, ind) => settingsVal?.[SETTINGS.VISIBLE_DATA]?.includes(ind) ?? true,
      ),
    [originalPalette, settingsVal],
  );

  const dataSeries = useMemo(
    () =>
      originalDataSeries.filter(
        (_, ind) => settingsVal?.[SETTINGS.VISIBLE_DATA]?.includes(ind) ?? true,
      ),
    [originalDataSeries, settingsVal],
  );

  const highlightedDataSeries = useMemo(() => {
    const scenariosData =
      propsData?.data?.filter(
        (_, ind) => settingsVal?.[SETTINGS.VISIBLE_DATA]?.includes(ind) ?? true,
      ) || [];

    return dataSeries.flatMap((series, seriesInd) => {
      const scenarioData = scenariosData[seriesInd] || [];

      const scenarioSeries = {
        ...series,
        data: scenarioData?.values.map((val, i) => ({
          x: xAxisVals[i],
          y: val,
        })),
        customColor: palette[seriesInd],
        id: `${series.id}-scenario`,
      };

      const highlights = series?.highlights || [];

      const { noHighlightRanges, highlightRanges } = getSeriesRanges(
        highlights,
        xAxisVals,
      );

      const getDataSeriesFromRanges = (
        s: any,
        ranges: [number, number][],
        isScenarios?: boolean,
      ) =>
        ranges.map(([start, end], ind) => ({
          ...s,
          data: s.data.map((d: any) => {
            const dataInd = xAxisVals.findIndex((val) => val === d.x);

            return {
              ...d,
              translucent: isScenarios,
              y: dataInd >= start && dataInd < end ? d.y : null,
            };
          }),
          customColor: palette[seriesInd],
          id: `${s.id}-${ind}`,
          isScenarios,
        }));

      return [
        ...highlightRanges.flatMap(({ ranges, highlight }, hTypeInd) => [
          ...transformHighlightSeries(
            getDataSeriesFromRanges(series, ranges, isScenarios),
            highlight,
            hTypeInd,
          ),
          ...(isScenarios
            ? transformHighlightSeries(
                getDataSeriesFromRanges(scenarioSeries, ranges),
                highlight,
                hTypeInd,
              )
            : []),
        ]),
        ...[
          ...transformNoHighlightSeries(
            getDataSeriesFromRanges(series, noHighlightRanges, isScenarios),
          ),
          ...(isScenarios
            ? transformNoHighlightSeries(
                getDataSeriesFromRanges(scenarioSeries, noHighlightRanges),
              )
            : []),
        ],
      ];
    });
  }, [
    dataSeries,
    isScenarios,
    palette,
    propsData?.data,
    settingsVal,
    xAxisVals,
  ]);

  const seriesColors = useMemo(() => {
    const series_colors: Record<string, string> = {};

    for (const idx in dataSeries) {
      const id = dataSeries[idx].id;
      if (typeof id !== 'undefined') {
        series_colors[id] = palette[idx];
      } else {
        series_colors[idx] = palette[idx];
      }
    }

    return series_colors;
  }, [palette, dataSeries]);

  const yDomain = useFormatGraphSeries(xAxisVals, dataSeries, [
    ...originalDataSeries,
    ...(isScenarios ? propsData?.data : []),
  ]);

  const [min, max] = yDomain;
  const { domain, ticks } = useScale({
    min,
    max,
    zeroBased: data.zeroBased,
    tickTotal: 8,
    maxValue: yAxis?.maxValue,
  });

  const uniqueTicks = generateUniqueTicks(
    ticks,
    yAxis?.valuesType,
    yAxis?.decimalPlaces,
  );

  const isSm = width <= WIDGET_BREAKPOINTS.SM;

  const formatYTicks = useCallback(
    (value: number) =>
      getFormattedValue(value, yAxis?.valuesType, yAxis?.decimalPlaces),
    [yAxis],
  );

  const YTicksWidth = useMemo(() => {
    const maxLength = uniqueTicks.reduce((acc: number, tick) => {
      const l = getFormattedValue(
        tick,
        yAxis?.valuesType,
        yAxis?.decimalPlaces,
      ).length;

      return l > acc ? l : acc;
    }, 0);

    return maxLength * 6.5;
  }, [uniqueTicks, yAxis]);

  const XTicksHeight = useMemo(() => {
    if (!xAxis?.tiltLabels) {
      return 15;
    }

    const maxTick = xAxisVals.reduce((acc, tick) => {
      return tick.length > acc.length ? tick : acc;
    }, '');

    const maxLength = getFormattedValue(
      maxTick,
      xAxis?.valuesType,
      xAxis.decimalPlaces,
    ).length;

    return maxLength * 5;
  }, [xAxis, xAxisVals]);

  const isDefaultEmpty = useMemo(
    () =>
      originalDataSeries.every(({ data }) => data.every(({ y }) => y === null)),
    [originalDataSeries],
  );

  const isScenariosEmpty = useMemo(
    () => isScenarios && propsData?.data?.every((d) => !d?.values?.length),
    [isScenarios, propsData?.data],
  );

  const isEmpty = isScenarios
    ? isDefaultEmpty && isScenariosEmpty
    : isDefaultEmpty;

  const enablePointsValue = data.showDots || 'auto';
  const enablePoints =
    enablePointsValue === 'auto'
      ? (!isSm && xAxisVals.length < 50) || xAxisVals.length === 1
      : enablePointsValue === 'always';

  const getAlertCase = () => {
    if (editProps && isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if ((!isDA && isEditing) || (isDA && !editProps)) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    return ErrorComponents.ALERT_WITH_RELOAD;
  };

  return (
    <ResizeSensor2
      onResize={(entries) => {
        setWidth(entries[0].contentRect.width);
      }}
    >
      <div className="widget">
        <SimpleFilter
          header={data.title}
          filters={filters}
          onFilterSelect={onFilterChange}
          info={data.info}
          className="u-margin-bottom-40"
          value={filtersVal}
          afterTitle={afterTitle}
          sliderContainerRef={sliderContainerRef}
          settings={settings}
          settingsVal={settingsVal}
          setSettingsVal={setSettingsVal}
          data_series={originalDataSeries}
          scenarios={data?.filterSections}
          isSm={isSm}
        />
        <div className={`chart-container`} data-testid="line_chart_container">
          {isEmpty ? (
            <WidgetError
              testId={'incomplete-data'}
              isDA={isDA}
              errorTitle={isDA ? t('error.widget.filter.title') : ''}
              alertCase={getAlertCase()}
              handleReload={handleReload ? handleReload : () => {}}
              handleDelete={handleDelete ? handleDelete : () => {}}
              editProps={
                editProps?.id ? editProps : { id: '', isPreview: false }
              }
            />
          ) : (
            <Line
              theme={NIVO_THEME}
              height={isEmbedded ? window.innerHeight - 150 : 300}
              width={width}
              margin={{
                top: 10,
                right: 30,
                bottom:
                  X_AXIS_TICK_PADDING +
                  XTicksHeight +
                  (xAxis?.title ? X_AXIS_TITLE : 0),
                left:
                  Y_AXIS_TICK_SIZE +
                  Y_AXIS_TICK_PADDING +
                  YTicksWidth +
                  (yAxis?.title ? Y_AXIS_TITLE : 0),
              }}
              data={highlightedDataSeries}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: domain[0],
                max: domain[1],
              }}
              colors={[]}
              lineWidth={3}
              axisBottom={{
                tickSize: 0,
                tickPadding: X_AXIS_TICK_PADDING,
                tickRotation: xAxis?.tiltLabels ? -50 : 0,
                tickValues:
                  !xAxis?.showAllLabels && (isSm || xAxisVals.length > 25)
                    ? getLabelValues(xAxisVals.length - 1, width, 5, 5).map(
                        (ind) => xAxisVals[ind],
                      )
                    : undefined,
                legend: xAxis?.title,
                legendOffset:
                  X_AXIS_TICK_PADDING + XTicksHeight + X_AXIS_TITLE_PADDING,
                legendPosition: 'middle',
                format: (value) =>
                  getFormattedValue(
                    value,
                    xAxis?.valuesType,
                    xAxis?.decimalPlaces,
                  ),
              }}
              axisLeft={{
                tickValues: uniqueTicks,
                tickSize: Y_AXIS_TICK_SIZE,
                tickPadding: Y_AXIS_TICK_PADDING,
                legend: yAxis?.title,
                legendOffset: -(
                  Y_AXIS_TICK_SIZE +
                  Y_AXIS_TICK_PADDING +
                  YTicksWidth +
                  Y_AXIS_TITLE_PADDING
                ),
                legendPosition: 'middle',
                format: formatYTicks,
              }}
              axisRight={{
                tickValues: undefined,
                format: () => '',
                tickSize: Y_AXIS_TICK_SIZE,
              }}
              enablePoints={enablePoints}
              pointSize={12}
              pointSymbol={PointSymbol}
              pointColor={{
                from: 'customColor',
              }}
              pointBorderWidth={3}
              pointBorderColor="white"
              enablePointLabel={settingsVal?.[SETTINGS.SHOW_VALUE]}
              pointLabel={({ x, y }) => {
                const pointVal = String(y);

                const series = dataSeries.find(({ data }) =>
                  data.find(
                    ({ x: _x, y: _y }) =>
                      _x === x && String(parseFloat(_y)) === pointVal,
                  ),
                );

                const seriesIndex = originalDataSeries.findIndex(
                  ({ id }) => id === series?.id,
                );

                return (
                  <tspan
                    style={{
                      fill:
                        selectedData === null || selectedData === seriesIndex
                          ? colors.monochromatic0
                          : UNSELECTED_COLOR,
                    }}
                  >
                    {getFormattedValue(
                      pointVal,
                      series?.type,
                      series?.decimalPlaces,
                    )}
                  </tspan>
                ) as unknown as string;
              }}
              animate={false}
              enableGridX={false}
              gridYValues={uniqueTicks}
              enableSlices="x"
              layers={[
                // @ts-ignore
                DomainHighlight(xAxis?.highlights || [], xAxisVals),
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                LineHighlight,
                'points',
                'slices',
              ]}
              markers={separators.flatMap(({ label, x }) => {
                return label.split('\n').map((line, ind) => ({
                  axis: 'x',
                  value: xAxisVals[x],
                  lineStyle: {
                    strokeDasharray: '5, 8',
                  },
                  legend: (
                    <tspan x="0" dy={ind * 12}>
                      {line}
                    </tspan>
                  ) as unknown as string,
                  textStyle: {
                    fontSize: '12px',
                    fill: colors.monochromatic2,
                    dominantBaseline: 'ideographic',
                  },
                }));
              })}
              sliceTooltip={({ slice: { points } }) => {
                const i = xAxisVals.findIndex(
                  (val) => val === points[0].data.x,
                );

                return (
                  <div className="p-crosshair">
                    <div className="p-crosshair__div u-padding-20">
                      {hoverHeaders && (
                        <div className="hover-header colorMonochromatic0 font-14">
                          {hoverHeaders[i]}
                        </div>
                      )}
                      {hoverSeries.map((series) => {
                        return (
                          <CrossOverContent
                            key={`${series.title}-LC-C`}
                            label={series.labels?.[i] || series.title}
                            value={series.info[i]}
                            circleStyles={
                              series.corresponds_to &&
                              seriesColors[series.corresponds_to]
                                ? {
                                    backgroundColor:
                                      seriesColors[series.corresponds_to],
                                    opacity: series.hasScenario
                                      ? TRANSLUCENT_OPACITY
                                      : 1,
                                  }
                                : undefined
                            }
                          />
                        );
                      })}
                      {(data.pivot?.aggregationLabel ||
                        data.pivot?.aggregationFunction) &&
                        hoverSeries.length && (
                          <AggregationFooter
                            domainValsIndex={i}
                            aggregationFunction={data.pivot.aggregationFunction}
                            aggregationLabel={data.pivot.aggregationLabel}
                            hoverSeries={hoverSeries}
                          />
                        )}
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>
        <div ref={sliderContainerRef} />
        <div className="d-flex u-display-space-between align-items-start">
          <div>
            <GraphLegend
              legend={originalDataSeries}
              visibleIds={settingsVal?.[SETTINGS.VISIBLE_DATA]}
              assignColor={(i: number) => originalPalette[i]}
              setSelectedData={setSelectedData}
              isScenarios={isScenarios}
              scenarios={data?.filterSections}
            />
          </div>
          <GraphStand stand={data.stand} />
        </div>
      </div>
    </ResizeSensor2>
  );
};

export default LineChart;
