import styled from 'styled-components';
import { SIDEBAR_WIDTH } from './Sidebar/styles';

export const Container = styled.div<{ adjustForTopNav: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  ${({ adjustForTopNav }) => adjustForTopNav && `padding-top: 64px;`}
`;

export const Content = styled.div<{ hasSideBar: boolean }>`
  width: ${({ hasSideBar }) =>
    hasSideBar ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%'};
  height: 100%;
  margin-left: ${({ hasSideBar }) => (hasSideBar ? `${SIDEBAR_WIDTH}px` : 0)};
`;
