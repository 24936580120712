import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LOGIN_ERROR_PATH,
  useIsAuthRoute,
} from '../Routes';
import NavBar, { NavBarData } from './NavBar';
import Sidebar from './Sidebar';
import { Container, Content } from './styles';
import { getIsEmbedded } from '../../containers/Embeddable/utils';
import { getIsPublicRoute } from '../utils';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const pathname = useLocation().pathname;
  const isLoginError = pathname === LOGIN_ERROR_PATH;
  const isAuthRoute = useIsAuthRoute();
  const isEmbedded = getIsEmbedded();
  const hasSideBarAndNavBar = !isAuthRoute && !isLoginError && !isEmbedded;
  const [pageTitle, setPageTitle] = useState('Polyteia');
  const [pageActions, setPageActions] = useState<React.JSX.Element[] | null>(
    null,
  );
  const isPublicPage = getIsPublicRoute();
  const hasNavBarOnly = isPublicPage && !isEmbedded && isAuthRoute;

  return (
    <Container adjustForTopNav={hasSideBarAndNavBar}>
      <NavBarData.Provider
        value={{ pageTitle, setPageTitle, pageActions, setPageActions }}
      >
        {hasSideBarAndNavBar && (
          <>
            <Sidebar />
            <NavBar />
          </>
        )}
        {hasNavBarOnly && <NavBar />}
        <Content hasSideBar={hasSideBarAndNavBar}>{children}</Content>
      </NavBarData.Provider>
    </Container>
  );
};

export default Layout;
