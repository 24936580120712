import { editor, Range } from 'monaco-editor';
import { Column, Parser, Select } from 'node-sql-parser';
import { useCallback, useEffect, useMemo } from 'react';
import { META_LABEL } from '../components/EditorPanels/MetaInfo';
import { NEW_QUERY } from '../containers/Queries/QueryEdit';
import { IdsField } from '../containers/Sites/SiteEdit/SiteSchema';
import { Metadata } from '../gql/common/types';
import { useAthenaMutation } from '../gql/dataSource/hooks';
import {
  CREATE_QUERY,
  CreateQueryData,
  CreateQueryVars,
} from '../gql/query/mutations';
import { QueryConfig } from '../gql/query/types';

export const formatEditor = (
  editor: editor.IStandaloneCodeEditor | null,
): void => {
  editor?.trigger(null, 'editor.action.formatDocument', null);
};

const cleanConfig = (key: string, value: unknown) =>
  key === '__typename' || value === null ? undefined : value;

export const cleanTypeName = (obj?: Record<string, any>) => {
  return obj ? JSON.parse(JSON.stringify(obj), cleanConfig) : '';
};

export const stringifyForEditor = (obj?: Record<string, any>) => {
  return JSON.stringify(obj, cleanConfig, '\t');
};

const parser = new Parser();

export const ASTERIX = '*';

type ParsedStringExpression = { type: 'string'; value: string };

export const getColumnNamesFromSqlQuery = (input: string): string[] | '*' => {
  try {
    const ast = parser.astify(input) as Select | Select[];

    const columns = Array.isArray(ast) ? ast[0].columns : ast.columns;

    if (columns === ASTERIX) {
      return columns;
    }

    return (columns as Column[]).map(({ as, expr }) => {
      if (as) {
        return as;
      }
      if (expr.type === 'column_ref') {
        return expr.table ? `${expr.table}.${expr.column}` : expr.column;
      }

      if ((expr.type as string) === 'string') {
        return (expr as unknown as ParsedStringExpression)?.value;
      }

      return '';
    });
  } catch (e) {
    return [];
  }
};

export const getRangeBetween = (
  model: editor.ITextModel,
  start: string,
  end: string,
) => {
  const startMatch = model.findNextMatch(
    start,
    { column: 1, lineNumber: 1 },
    false,
    true,
    '',
    false,
  );

  if (!startMatch) {
    return null;
  }

  const startPosition = startMatch.range.getEndPosition();

  const endMatch = model.findNextMatch(
    end,
    startPosition,
    false,
    true,
    '',
    false,
  );

  if (!endMatch) {
    return null;
  }

  const endPosition = endMatch.range.getEndPosition();

  return new Range(
    startPosition.lineNumber,
    startPosition.column,
    endPosition.lineNumber,
    endPosition.column,
  );
};

export const getMetaDescription = (meta: Metadata) =>
  Object.entries(meta).reduce(
    (acc, [key, val]) =>
      acc + (val && META_LABEL[key] ? `- ${META_LABEL[key]}: ${val}\n` : ''),
    '',
  );

const ADD_NEW_QUERY = '+ Add new query';

type FindResult = [Record<any, any>, string] | null;

const findObjByFieldValue = (
  obj: Record<any, any>,
  fieldValue: string,
): FindResult => {
  let result: FindResult = null;

  const find = (obj: Record<any, any>) => {
    if (result) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === fieldValue) {
        result = [obj, key];

        return;
      }

      const o = obj[key];

      if (o && typeof o === 'object') {
        find(o);
      }
    });
  };

  find(obj);

  return result;
};

export const useCreateQueryFromEditor = (
  rawQueryIdsField: IdsField,
  queriesNonLiveIds: string[],
  editorValue: string,
  setEditorValue: (val: string) => void,
  refetchQueries: () => void,
  refetchNonLiveQueries: () => void,
): IdsField => {
  const [createQuery] = useAthenaMutation<CreateQueryData, CreateQueryVars>(
    CREATE_QUERY,
  );

  const createQueryFromEditor = useCallback(async () => {
    try {
      const config: Record<any, any> = JSON.parse(editorValue);

      const res = findObjByFieldValue(config, ADD_NEW_QUERY);

      if (!res) {
        return;
      }

      const [obj, key] = res;

      obj[key] = 'Creating new query...';

      setEditorValue(stringifyForEditor(config));

      const { data: newQueryData } = await createQuery({
        variables: {
          CreateQueryInput: {
            queryConfig: {
              query: NEW_QUERY,
              name: 'New Query',
              title: 'New Query',
            } as QueryConfig,
          },
        },
      });

      const newConfig = newQueryData?.createQuery;

      if (!newConfig) {
        return;
      }

      await refetchQueries();
      await refetchNonLiveQueries();

      obj[key] = newConfig.id;

      setEditorValue(stringifyForEditor(config));

      window.open(
        `${window.location.origin}/queries/${newConfig.versionId}`,
        '_blank',
      );
    } catch (e) {}
  }, [
    createQuery,
    editorValue,
    refetchNonLiveQueries,
    refetchQueries,
    setEditorValue,
  ]);

  useEffect(() => {
    createQueryFromEditor();
  }, [createQueryFromEditor]);

  return useMemo(
    () => ({
      ...rawQueryIdsField,
      enum: [ADD_NEW_QUERY, ...rawQueryIdsField.enum, ...queriesNonLiveIds],
      enumDescriptions: [
        'Create new query',
        ...rawQueryIdsField.enumDescriptions,
      ],
      markdownEnumDescriptions: [
        'Create new query',
        ...rawQueryIdsField.markdownEnumDescriptions,
      ],
    }),
    [queriesNonLiveIds, rawQueryIdsField],
  );
};

export const usePreviewQueries = (
  rawQueryIdsField: IdsField,
  queriesNonLiveIds: string[],
): IdsField => {
  return useMemo(
    () => ({
      ...rawQueryIdsField,
      enum: [...rawQueryIdsField.enum, ...queriesNonLiveIds],
      enumDescriptions: [...rawQueryIdsField.enumDescriptions],
      markdownEnumDescriptions: [...rawQueryIdsField.markdownEnumDescriptions],
    }),
    [queriesNonLiveIds, rawQueryIdsField],
  );
};
