import React, { FC, Fragment } from 'react';
import {
  LegendGradient,
  LegendValues,
  LegendValuesSet,
  LegendValuesSetsWrapper,
  OneValueColor,
  OneValueVal,
  OneValueWrapper,
  MapLegendWrapper,
  GradientsWrapper,
  LegendValueZero,
} from './styles';
import {
  getFormattedValue,
  NUMBER_FORMAT_TYPES,
  transformNumber,
} from '../../../../utils/format';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../../../../gql/widget/types';

const getLegendValues = (
  max: number,
  min: number,
  isFromMin: boolean,
  steps: number,
  negative?: boolean,
) => {
  const showDecimals = Math.abs(max) <= 10;
  const stepsNum = steps - 2;
  const legendMin = isFromMin ? min : 0;

  let legendStep = (max - legendMin) / (steps - 1);

  if (!showDecimals) {
    legendStep = Math.round(legendStep);
  }

  let step = max;

  return [
    max,
    ...Array(stepsNum)
      .fill(null)
      .map(() => (step -= legendStep))
      .map((val) => {
        if (!negative && val < 0) {
          val = 0;
        }

        if (negative && val >= 0) {
          val = 0;
        }

        return showDecimals ? parseFloat(val.toFixed(2)) : Math.round(val);
      }),
    legendMin,
  ];
};

const roundForLegend = (
  s: string,
  type: CONFIG_ELEMENT_VALUES_TYPE,
  decimalPlaces?: number,
) => {
  const isNumberFormat = NUMBER_FORMAT_TYPES.includes(type);
  decimalPlaces = decimalPlaces ?? 0;

  const [number, ...rest] = s.split(' ');

  let n = parseFloat(
    isNumberFormat ? number.replace(/\./g, '').replace(/,/g, '.') : number,
  );

  const absN = Math.abs(n);
  const power = Math.pow(10, decimalPlaces);

  if (absN < 10) {
    n = Math.round(n * power) / power;
  } else if (absN < 100) {
    n = Math.round(n);
  } else {
    n = Math.round(n / power) * power;
  }

  return `${transformNumber(String(n))}${
    rest.length ? ` ${rest.join(' ')}` : ''
  }`;
};

const mapLegendValues = (
  values: number[],
  dataType: CONFIG_ELEMENT_VALUES_TYPE,
  decimalPlaces?: number,
) =>
  values.map((val, ind) => {
    const renderValue = getFormattedValue(String(val), dataType, decimalPlaces);

    return (
      <div key={`${ind}-${val}`}>
        {roundForLegend(renderValue, dataType, decimalPlaces)}
      </div>
    );
  });

type OneValueProps = {
  value: number;
  color: string;
  dataType: CONFIG_ELEMENT_VALUES_TYPE;
  decimalPlaces?: number;
};

const OneValue: FC<OneValueProps> = ({
  value,
  color,
  dataType,
  decimalPlaces,
}) => {
  const renderValue = getFormattedValue(value, dataType, decimalPlaces);

  return (
    <OneValueWrapper>
      <OneValueColor color={color} />
      <OneValueVal>
        {getFormattedValue(renderValue, dataType, decimalPlaces)}
      </OneValueVal>
    </OneValueWrapper>
  );
};

type Props = {
  positiveColor: string;
  negativeColor: string;
  isFromMin: boolean;
  neutralColor: string;
  originalValues: number[];
  minPositiveColor: string;
  minNegativeColor: string;
  hasBoth: boolean;
  hasPositive: boolean;
  hasNegative: boolean;
  onePositive: boolean;
  oneNegative: boolean;
  positives: number[];
  negatives: number[];
  dataType: CONFIG_ELEMENT_VALUES_TYPE;
  decimalPlaces?: number;
};

const MapLegend: FC<Props> = ({
  positiveColor,
  negativeColor,
  isFromMin,
  neutralColor,
  originalValues,
  minNegativeColor,
  minPositiveColor,
  hasBoth,
  hasPositive,
  hasNegative,
  onePositive,
  positives,
  oneNegative,
  negatives,
  dataType,
  decimalPlaces,
}) => {
  let legendValuesStepsCount = 5;

  if (!isFromMin && hasBoth) {
    legendValuesStepsCount = 3;
  }

  const max = Math.max(...originalValues);
  const min = Math.min(...originalValues);

  const negativeMin = Math.max(...negatives);
  const positiveMin = Math.min(...positives);

  const isSingleValue = max === min;
  let singleValueColor;
  if (max === 0) {
    singleValueColor = minPositiveColor;
  } else {
    singleValueColor = max < 0 ? negativeColor : positiveColor;
  }

  const legendPositiveValues = getLegendValues(
    max,
    positiveMin,
    isFromMin,
    legendValuesStepsCount,
  );
  const legendNegativeValues = getLegendValues(
    min,
    negativeMin,
    isFromMin,
    legendValuesStepsCount,
    true,
  );

  const positiveLegendStartsWithZero =
    hasPositive && legendPositiveValues[legendPositiveValues.length - 1] === 0;
  const negativeLegendStartsWithZero =
    hasNegative && legendNegativeValues[legendNegativeValues.length - 1] === 0;

  const shouldIncludeZeroValue =
    positiveLegendStartsWithZero && negativeLegendStartsWithZero;

  if (shouldIncludeZeroValue) {
    legendPositiveValues.pop();
    legendNegativeValues.pop();
  }

  const FromZeroValues = () => {
    if (hasBoth) {
      return (
        <LegendValuesSetsWrapper>
          <LegendValuesSet>
            {mapLegendValues(legendPositiveValues, dataType, decimalPlaces)}
          </LegendValuesSet>
          {shouldIncludeZeroValue && (
            <LegendValueZero
              surroundingElementsCount={
                legendPositiveValues.length + legendNegativeValues.length
              }
            >
              {mapLegendValues([0], dataType, decimalPlaces)}
            </LegendValueZero>
          )}
          <LegendValuesSet negative>
            {mapLegendValues(legendNegativeValues, dataType, decimalPlaces)}
          </LegendValuesSet>
        </LegendValuesSetsWrapper>
      );
    }

    return (
      <LegendValues negative={!hasPositive}>
        {mapLegendValues(
          hasPositive ? legendPositiveValues : legendNegativeValues,
          dataType,
          decimalPlaces,
        )}
      </LegendValues>
    );
  };

  return (
    <Fragment>
      <MapLegendWrapper double={isFromMin && hasBoth}>
        {isSingleValue ? (
          <OneValue
            value={max}
            color={singleValueColor}
            dataType={dataType}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <Fragment>
            <GradientsWrapper>
              {!isFromMin ? (
                <Fragment>
                  <LegendGradient
                    minColor={hasNegative ? negativeColor : minPositiveColor}
                    maxColor={hasPositive ? positiveColor : minNegativeColor}
                    neutralColor={hasBoth ? neutralColor : undefined}
                  />
                  <FromZeroValues />
                </Fragment>
              ) : (
                <Fragment>
                  {hasPositive && (
                    <Fragment>
                      <LegendGradient
                        minColor={minPositiveColor}
                        maxColor={positiveColor}
                      />
                      <LegendValues>
                        {mapLegendValues(
                          legendPositiveValues,
                          dataType,
                          decimalPlaces,
                        )}
                      </LegendValues>
                    </Fragment>
                  )}
                  <LegendValues>
                    {shouldIncludeZeroValue &&
                      mapLegendValues([0], dataType, decimalPlaces)}
                  </LegendValues>
                  {hasNegative && (
                    <Fragment>
                      <LegendGradient
                        minColor={minNegativeColor}
                        maxColor={negativeColor}
                      />
                      <LegendValues>
                        {mapLegendValues(
                          legendNegativeValues,
                          dataType,
                          decimalPlaces,
                        )}
                      </LegendValues>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </GradientsWrapper>
            {onePositive && (
              <OneValue
                value={positives[0]}
                color={positiveColor}
                dataType={dataType}
                decimalPlaces={decimalPlaces}
              />
            )}
            {oneNegative && (
              <OneValue
                value={negatives[0]}
                color={negativeColor}
                dataType={dataType}
                decimalPlaces={decimalPlaces}
              />
            )}
          </Fragment>
        )}
      </MapLegendWrapper>
    </Fragment>
  );
};

export default MapLegend;
