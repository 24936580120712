import styled from 'styled-components';
import { colors } from '../../../../utils/colors';

export const LeafletMapWrapper = styled.div<{
  height: string;
  hideBg?: boolean;
}>`
  width: 100%;
  height: ${({ height }) => height};
  position: relative;
  z-index: 0;

  .leaflet-container {
    font-family: inherit;
    background: ${({ hideBg }) => (hideBg ? 'white' : '#ddd')};
  }

  .leaflet-tooltip {
    opacity: 1;
    color: #000;
    font-weight: 500;
    box-shadow: none;
  }

  .leaflet-pane ~ div {
    z-index: 1000;
  }

  .leaflet-tile-pane {
    opacity: 0.65;
  }

  .leaflet-marker-icon {
    background: none;
    border: 0;

    span {
      white-space: nowrap;
    }
  }
`;

const LEGEND_WIDTH = 70;

export const MapLegendWrapper = styled.div<{ double: boolean }>`
  min-height: ${({ double }) => (double ? LEGEND_WIDTH * 2 : LEGEND_WIDTH)}px;
  margin: 10px;
  width: 400px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const GradientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LegendValues = styled.div<{ negative?: boolean }>`
  padding-left: 5px;
  margin-right: 10px;
  display: flex;
  flex-direction: ${({ negative }) => (negative ? 'row' : 'row-reverse')};
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: ${colors.monochromatic0};
  text-align: start;

  & > div {
    padding-bottom: 5px;
  }
`;

export const LegendValuesSetsWrapper = styled(LegendValues)`
  & > div {
    padding-bottom: 0;
  }
`;

export const LegendValuesSet = styled.div<{ negative?: boolean }>`
  display: flex;
  flex-direction: ${({ negative }) => (negative ? 'row' : 'row-reverse')};
  width: 45%;
  justify-content: space-between;
  text-align: end;
`;

export const LegendValueZero = styled.div<{ surroundingElementsCount: number }>`
  width: auto;
  margin: ${({ surroundingElementsCount }) =>
    `0 ${Math.floor(40 / (surroundingElementsCount - 2)) - 2}%`};
`;

export const LegendGradient = styled.div<{
  minColor: string;
  maxColor: string;
  neutralColor?: string;
}>`
  background: linear-gradient(
    -90deg,
    ${({ minColor, maxColor, neutralColor }) =>
      `${maxColor},${neutralColor ? ` ${neutralColor},` : ''} ${minColor}`}
  );
  height: 10px;
  width: 100%;
  flex-shrink: 0;
  border: 1px solid #fafafa;
`;

export const OneValueColor = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  flex-shrink: 0;
  background-color: ${({ color }) => color};
`;

export const OneValueVal = styled.div`
  padding-left: 5px;
  color: ${colors.monochromatic2};
`;

export const OneValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const ZoomControlsWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const ZoomControlBtn = styled.div`
  cursor: pointer;
  padding: 5px;
  background-color: white;
  height: 29px;
  position: relative;

  &:hover {
    background-color: ${colors.monochromatic5};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 18px;
    border-bottom: 1px solid ${colors.monochromatic5};
  }
`;
