import { useCurrentUserQuery } from '../../gql/user/hooks';
import {
  getIsDA,
  getIsGroupEditor,
  getIsAnalyticsEngineer,
} from '../../gql/user/local';
import { Feature } from '../../gql/user/types';

export const useCanEmbed = () => {
  const isDA = getIsDA();
  const isEditor = getIsGroupEditor();
  const isAE = getIsAnalyticsEngineer();

  const { features, loadingUser } = useCurrentUserQuery();

  return {
    loading: loadingUser,
    canEmbed:
      isDA || (features?.includes(Feature.publish) && (isEditor || isAE)),
  };
};

export const useOrganisationHasPublish = () => {
  const isDA = getIsDA();
  const { features, loadingUser } = useCurrentUserQuery();

  return {
    loading: loadingUser,
    hasPublish: isDA || features?.includes(Feature.publish),
  };
};
