import { Field, Formik } from 'formik';
import { DragEvent, RefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddFilterIllustrationIcon } from '../../../assets/icons/illustration/standard/add-filter.svg';
import { ReactComponent as FilterIllustrationIcon } from '../../../assets/icons/illustration/standard/filter.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ui/l/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/ui/l/edit.svg';
import { DraggableListItem } from '../../../components/DraggableListItem';
import { DropField } from '../../../components/DropField';
import { DropdownList, Option } from '../../../components/DropdownList';
import { IconButton } from '../../../components/IconButton';
import { Modal } from '../../../components/Modal';
import { DataType } from '../../../components/PivotChartPanel';
import {
  ALL_DATA_TYPES,
  FIELD_DRAG_DATA_TYPE,
  Field as PivotField,
  TableField,
  getIcon,
} from '../../../components/PivotChartPanel/PivotChartPanel';
import { InputLabel } from '../../../components/inputs/InputLabel';
import { Select } from '../../../components/inputs/Select';
import { TextInput } from '../../../components/inputs/TextInput';
import { Toggle } from '../../../components/inputs/Toggle';
import { FILTER_TYPES } from '../../../gql/widget/types';
import { useModal } from '../../../utils/hooks/modal';

const FILTER_FIELD_INPUT_ID = 'filter-field';

export const FILTER_DRAG_DATA_TYPE = 'text/x-pivot-chart-panel-filter';

export const ADD_FILTER_MODAL_KEY = 'ADD_FILTER_MODAL_KEY';
const EDIT_FILTER_MODAL_KEY = 'EDIT_FILTER_MODAL_KEY';

const ALL_FILTER_TYPES = [
  'dropdown-with-search',
  'dropdown-without-search',
  'multi-select-dropdown-with-search',
  'multi-select-dropdown-without-search',
  'free-text',
  'slider',
] as const;
export type FilterType = (typeof ALL_FILTER_TYPES)[number];

const SINGLE_FILTER_TYPES = [
  'dropdown-with-search',
  'dropdown-without-search',
  'free-text',
  'slider',
] as const;

export const adaptFilterType = (
  type?: FilterType,
): FILTER_TYPES | undefined => {
  switch (type) {
    case 'dropdown-with-search':
      return FILTER_TYPES.SEARCHABLE_DROPDOWN;

    case 'dropdown-without-search':
      return FILTER_TYPES.DROPDOWN;

    case 'multi-select-dropdown-with-search':
      return FILTER_TYPES.MULTISELECT_SEARCHABLE;

    case 'multi-select-dropdown-without-search':
      return FILTER_TYPES.MULTISELECT_DROPDOWN;

    case 'free-text':
      return FILTER_TYPES.FREETEXT;

    case 'slider':
      return FILTER_TYPES.SLIDER;

    default:
      return type;
  }
};

export const convertFilterType = (
  type?: FILTER_TYPES,
): FilterType | undefined => {
  switch (type) {
    case FILTER_TYPES.SEARCHABLE_DROPDOWN:
      return 'dropdown-with-search';

    case FILTER_TYPES.DROPDOWN:
      return 'dropdown-without-search';

    case FILTER_TYPES.MULTISELECT_SEARCHABLE:
      return 'multi-select-dropdown-with-search';

    case FILTER_TYPES.MULTISELECT_DROPDOWN:
      return 'multi-select-dropdown-without-search';

    case FILTER_TYPES.FREETEXT:
      return 'free-text';

    case FILTER_TYPES.SLIDER:
      return 'slider';

    case FILTER_TYPES.FREETEXT_VECTOR:
      return undefined;

    default:
      return type;
  }
};

const isTotalConfigurable = (type?: FilterType) =>
  type === 'dropdown-with-search' ||
  type === 'dropdown-without-search' ||
  type === 'multi-select-dropdown-with-search' ||
  type === 'multi-select-dropdown-without-search';

export type Filter = {
  id: string;
  field?: PivotField;
  dataType?: DataType;
  title?: string;
  type?: FilterType;
  showTotal?: boolean;
  totalLabel?: string;
  defaultSelectedFilter?: string;
};

type Props = {
  fields?: PivotField[];
  filters?: Filter[];
  setRowField: (field?: PivotField) => void;
  setValueField: (field?: PivotField) => void;
  setColumnField: (field?: PivotField) => void;
  addFilter: (filter: Omit<Filter, 'id'>) => void;
  editFilter: (id: string, filter: Omit<Filter, 'id'>) => void;
  removeFilter: (id: string) => void;
  draggingField: TableField | null;
  setDraggingField: (field: TableField | null) => void;
  filterFieldToAdd: PivotField | undefined;
  setFilterFieldToAdd: (field: PivotField | undefined) => void;
  openedFieldInput: string | undefined;
  setOpenedFieldInput: (fieldInput: string | undefined) => void;
};

export const FilterPanel = ({
  fields = [],
  filters,
  setRowField,
  setValueField,
  setColumnField,
  addFilter,
  editFilter,
  removeFilter,
  draggingField,
  setDraggingField,
  filterFieldToAdd,
  setFilterFieldToAdd,
  openedFieldInput,
  setOpenedFieldInput,
}: Props) => {
  const { t } = useTranslation();
  const filterRef = useRef<HTMLDivElement>(null);
  const [filterToEdit, setFilterToEdit] = useState<Filter>();

  const { isModalOpen, openModal, closeModal } = useModal();
  const isAddFilterModalOpen = isModalOpen(ADD_FILTER_MODAL_KEY);
  const isEditFilterModalOpen = isModalOpen(EDIT_FILTER_MODAL_KEY);

  const fieldItems: Option<PivotField>[] = useMemo(
    () =>
      fields.map((field) => ({
        label: field.name,
        key: field.id,
        value: field,
        icon: getIcon(field.type),
      })),
    [fields],
  );

  const resetDraggingField = (e: DragEvent<HTMLDivElement>) => {
    if (!(navigator.platform.match('Mac') ? e.altKey : e.ctrlKey)) {
      switch (draggingField) {
        case 'row':
          setRowField(undefined);
          break;
        case 'value':
          setValueField(undefined);
          break;
        case 'column':
          setColumnField(undefined);
          break;
        case 'filter':
          const filterId = e.dataTransfer.getData(FILTER_DRAG_DATA_TYPE);
          removeFilter(filterId);
          break;
      }
    }
  };

  const onDropFilterField = (e: DragEvent<HTMLDivElement>) => {
    resetDraggingField(e);

    const fieldId = e.dataTransfer.getData(FIELD_DRAG_DATA_TYPE);
    setFilterFieldToAdd(fields.find(({ id }) => id === fieldId));
    openModal(ADD_FILTER_MODAL_KEY);
  };

  const handleClickDropField =
    (id: string, ref: RefObject<HTMLDivElement>) => () => {
      setOpenedFieldInput(id);

      const clickOutsideHandler = (event: MouseEvent | TouchEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          setOpenedFieldInput(undefined);
        }
      };

      document.addEventListener('click', clickOutsideHandler, { once: true });
      document.addEventListener('touchstart', clickOutsideHandler, {
        once: true,
      });
    };

  return (
    <>
      <div className="pt-2" ref={filterRef}>
        <InputLabel htmlFor={FILTER_FIELD_INPUT_ID}>
          <div className="flex flex-col gap-2">
            {filters?.map(
              (filter) =>
                filter.field && (
                  <DraggableListItem
                    key={filter.id}
                    state="default"
                    icon={getIcon(filter.field.type)}
                    title={filter.field.name}
                    badge={
                      filter.field.contentType === 'measure'
                        ? t('pivot.measure')
                        : filter.field.contentType === 'dimension'
                        ? t('pivot.dimension')
                        : ''
                    }
                    buttons={() => [
                      <IconButton
                        key="edit"
                        ariaLabel={t('common.edit')}
                        icon={EditIcon}
                        onClick={() => {
                          setFilterToEdit(filter);
                          openModal(EDIT_FILTER_MODAL_KEY);
                        }}
                      />,
                      <IconButton
                        key="delete"
                        ariaLabel={t('common.delete')}
                        icon={DeleteIcon}
                        onClick={() => removeFilter(filter.id)}
                      />,
                    ]}
                    additionalTextTitle={t('pivot.filter-title')}
                    additionalText={
                      filter.type && t(`pivot.filter-type.${filter.type}`)
                    }
                    onDragStart={(e) => {
                      if (filter.field) {
                        e.dataTransfer.setData(
                          FIELD_DRAG_DATA_TYPE,
                          filter.field.id,
                        );
                        e.dataTransfer.setData(
                          FILTER_DRAG_DATA_TYPE,
                          filter.id,
                        );
                        setDraggingField('filter');
                      } else {
                        console.error(
                          'Failed to drag filter field: Invalid field.',
                        );
                      }
                    }}
                    onDragEnd={() => setDraggingField(null)}
                    onDrop={(e) => onDropFilterField(e)}
                    droppable
                  />
                ),
            )}
            <DropField
              size="small"
              title={t('pivot.drop.title')}
              onDrop={(e) => onDropFilterField(e)}
              onClick={handleClickDropField(FILTER_FIELD_INPUT_ID, filterRef)}
              content={
                openedFieldInput === FILTER_FIELD_INPUT_ID && (
                  <div
                    className="absolute z-20 w-full"
                    style={{ left: 0, top: 'calc(100% + 2px)' }}
                  >
                    <DropdownList
                      onSelect={(field) => {
                        setOpenedFieldInput(undefined);
                        setFilterFieldToAdd(field);
                        openModal(ADD_FILTER_MODAL_KEY);
                      }}
                      items={fieldItems}
                      multiple={false}
                      showCheckmark={false}
                      showIcon
                    />
                  </div>
                )
              }
            />
          </div>
        </InputLabel>
      </div>
      {(isAddFilterModalOpen || isEditFilterModalOpen) && (
        <Formik<{
          filter: Omit<Filter, 'id'>;
        }>
          initialValues={{
            filter: {
              field: isAddFilterModalOpen
                ? filterFieldToAdd
                : fields.find(({ id }) => id === filterToEdit?.field?.id),
              dataType: isAddFilterModalOpen
                ? filterFieldToAdd?.type
                : filterToEdit?.dataType ?? filterToEdit?.field?.type,
              title: isAddFilterModalOpen
                ? filterFieldToAdd?.name
                : filterToEdit?.title ?? filterToEdit?.field?.name,
              type: isAddFilterModalOpen ? undefined : filterToEdit?.type,
              showTotal: isAddFilterModalOpen
                ? undefined
                : filterToEdit?.showTotal,
              totalLabel: isAddFilterModalOpen
                ? undefined
                : filterToEdit?.totalLabel,
              defaultSelectedFilter: isAddFilterModalOpen
                ? undefined
                : filterToEdit?.defaultSelectedFilter,
            },
          }}
          onSubmit={(values) => {
            if (isAddFilterModalOpen) {
              addFilter(values.filter);
            } else if (filterToEdit?.id) {
              editFilter(filterToEdit.id, values.filter);
            } else {
              console.error('Failed to edit filter with invalid ID.');
            }
            closeModal();
          }}
        >
          {(props) => (
            <Modal
              acceptCaption={t('common.save')}
              cancelCaption={t('common.cancel')}
              icon={
                isAddFilterModalOpen ? (
                  <AddFilterIllustrationIcon />
                ) : (
                  <FilterIllustrationIcon />
                )
              }
              title={
                isAddFilterModalOpen
                  ? t('pivot.add-filter-title')
                  : t('pivot.edit-filter-title')
              }
              size="small"
              acceptButtonDisabled={
                !props.values.filter.field ||
                !props.values.filter.dataType ||
                !props.values.filter.type ||
                (props.values.filter.showTotal &&
                  !props.values.filter.totalLabel)
              }
              onAccept={props.submitForm}
              onClose={closeModal}
              onCancel={closeModal}
              transitionOut
            >
              <div className="flex flex-col gap-2 rounded-lg bg-concrete-jungle-8 p-2">
                <div className="grid grid-cols-2 gap-4">
                  <Field
                    name="filter.field"
                    component={Select<PivotField>}
                    id="filter-input-filter-field"
                    placeholder={t(
                      'pivot.filter-input.filter-field-placeholder',
                    )}
                    label={t('pivot.filter-input.filter-field-label')}
                    options={fieldItems}
                    showIcon
                  />
                  <Field
                    name="filter.dataType"
                    component={Select<DataType>}
                    id="filter-input-filter-data-type"
                    placeholder={t(
                      'pivot.filter-input.filter-data-type-placeholder',
                    )}
                    label={t('pivot.filter-input.filter-data-type-label')}
                    options={ALL_DATA_TYPES.map((type) => ({
                      // t('wizards.csv.tableDescription.form.dataType.options.string')
                      // t('wizards.csv.tableDescription.form.dataType.options.number')
                      // t('wizards.csv.tableDescription.form.dataType.options.date')
                      label: t(
                        `wizards.csv.tableDescription.form.dataType.options.${type}`,
                      ),
                      key: type,
                      value: type,
                      icon: getIcon(type),
                    }))}
                    showIcon
                    required
                  />
                </div>
                <Field
                  name="filter.title"
                  component={TextInput}
                  id="filter-input-filter-title"
                  placeholder={t('pivot.filter-input.filter-title-placeholder')}
                  label={t('pivot.filter-input.filter-title-label')}
                />
                <Field
                  name="filter.type"
                  component={Select<FilterType>}
                  id="filter-input-filter-type"
                  placeholder={t('pivot.filter-input.filter-type-placeholder')}
                  label={t('pivot.filter-input.filter-type-label')}
                  options={ALL_FILTER_TYPES.filter(
                    (type) =>
                      type !== 'free-text' ||
                      props.values.filter.dataType === 'string',
                  ).map((type) => ({
                    // t('pivot.filter-type.dropdown-with-search')
                    // t('pivot.filter-type.dropdown-without-search')
                    // t('pivot.filter-type.multi-select-dropdown-with-search')
                    // t('pivot.filter-type.multi-select-dropdown-without-search')
                    // t('pivot.filter-type.free-text')
                    // t('pivot.filter-type.slider')
                    label: t(`pivot.filter-type.${type}`),
                    key: type,
                    value: type,
                  }))}
                  required
                />
                {SINGLE_FILTER_TYPES.some(
                  (type) => type === props.values.filter.type,
                ) && (
                  <Field
                    name="filter.defaultSelectedFilter"
                    component={TextInput}
                    id="filter-input-filter-default-selected-filter"
                    placeholder={t(
                      'pivot.filter-input.filter-default-selected-filter-placeholder',
                    )}
                    label={t(
                      'pivot.filter-input.filter-default-selected-filter-label',
                    )}
                  />
                )}
                {isTotalConfigurable(props.values.filter.type) && (
                  <div className="flex gap-4">
                    <Field
                      name="filter.showTotal"
                      component={Toggle}
                      id="filter-input-filter-show-total"
                      label={t('pivot.filter-input.filter-show-total-label')}
                    />
                    {props.values.filter.showTotal && (
                      <div className="grow">
                        <Field
                          name="filter.totalLabel"
                          component={TextInput}
                          id="filter-input-filter-total-label"
                          placeholder={t(
                            'pivot.filter-input.filter-total-label-placeholder',
                          )}
                          label={t(
                            'pivot.filter-input.filter-total-label-label',
                          )}
                          required
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Modal>
          )}
        </Formik>
      )}
    </>
  );
};
