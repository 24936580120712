import { formatDialect, postgresql } from 'sql-formatter';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../gql/widget/types';
import { format } from 'date-fns';

const DEFAULT_DECIMAL_PLACES = 2;

export const AXIS_FORMAT_THRESHOLD = 10000;

const formatDate = (date: Date) => {
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  };

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
};

export const transformNumber = (val: string) => {
  if (!val) {
    return val;
  }

  let [integerPart, decimalPart] = val.split('.');

  integerPart = integerPart.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');

  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
};

export const transformDate = (val: string) => {
  if (!val) {
    return val;
  }

  const date = new Date(val);

  return formatDate(date);
};

export const TRANSFORM_UNITS = {
  [CONFIG_ELEMENT_VALUES_TYPE.string]: '',
  [CONFIG_ELEMENT_VALUES_TYPE.number]: '',
  [CONFIG_ELEMENT_VALUES_TYPE.currencyEUR]: '€',
  [CONFIG_ELEMENT_VALUES_TYPE.percent]: '%',
  [CONFIG_ELEMENT_VALUES_TYPE.sqkm]: 'km²',
  [CONFIG_ELEMENT_VALUES_TYPE.celsius]: '°C',
  [CONFIG_ELEMENT_VALUES_TYPE.ugperm3]: 'μg/m³',
  [CONFIG_ELEMENT_VALUES_TYPE.date]: '',
};

export const NUMBER_FORMAT_TYPES = [
  CONFIG_ELEMENT_VALUES_TYPE.celsius,
  CONFIG_ELEMENT_VALUES_TYPE.currencyEUR,
  CONFIG_ELEMENT_VALUES_TYPE.number,
  CONFIG_ELEMENT_VALUES_TYPE.percent,
  CONFIG_ELEMENT_VALUES_TYPE.sqkm,
  CONFIG_ELEMENT_VALUES_TYPE.ugperm3,
];

const TRANSFORM_FUNC: Record<string, (val: string) => string> = {
  [CONFIG_ELEMENT_VALUES_TYPE.celsius]: transformNumber,
  [CONFIG_ELEMENT_VALUES_TYPE.currencyEUR]: transformNumber,
  [CONFIG_ELEMENT_VALUES_TYPE.date]: transformDate,
  [CONFIG_ELEMENT_VALUES_TYPE.number]: transformNumber,
  [CONFIG_ELEMENT_VALUES_TYPE.percent]: transformNumber,
  [CONFIG_ELEMENT_VALUES_TYPE.sqkm]: transformNumber,
  [CONFIG_ELEMENT_VALUES_TYPE.ugperm3]: transformNumber,
};

const getFormattedDate = (value: string): string => {
  const parsedDate = Date.parse(value);
  return format(parsedDate, 'dd.MM.yyyy');
};

export const getFormattedValue = (
  value: string | number,
  type = CONFIG_ELEMENT_VALUES_TYPE.string,
  decimalPlaces?: number,
): string => {
  try {
    if (value === null) {
      return '-';
    }
    if (type === 'date') {
      return getFormattedDate(value.toString());
    }
    if (value === '' || type === 'string' || !type) {
      return value.toString();
    }
    if (
      decimalPlaces === undefined ||
      decimalPlaces === null ||
      decimalPlaces < 0 ||
      decimalPlaces > 10
    ) {
      decimalPlaces = DEFAULT_DECIMAL_PLACES;
    }
    const nVal = +value;

    const fixedVal =
      nVal < AXIS_FORMAT_THRESHOLD
        ? !isNaN(nVal)
          ? nVal.toFixed(nVal % 1 ? decimalPlaces : 0)
          : value
        : formatBigNumber(nVal);

    const transformedVal =
      TRANSFORM_FUNC[type]?.(fixedVal.toString()) || fixedVal;
    const unit = TRANSFORM_UNITS[type] || '';

    return `${transformedVal}${unit && ` ${unit}`}`;
  } catch (e) {
    return '-';
  }
};

export const formatBigNumber = (
  value: number,
  type = CONFIG_ELEMENT_VALUES_TYPE.string,
): string => {
  const absValue = Math.abs(value);

  if (absValue < AXIS_FORMAT_THRESHOLD) {
    return absValue.toString();
  }

  const BIG_NUMBERS = {
    MILLION: 1000000,
    BILLION: 1000000000,
    TRILLION: 1000000000000,
  };

  const NUMBER_DESCRIPTIONS = {
    [BIG_NUMBERS.MILLION]: 'Mio.',
    [BIG_NUMBERS.BILLION]: 'Mrd.',
    [BIG_NUMBERS.TRILLION]: 'Bill.',
  };

  if (absValue < BIG_NUMBERS.MILLION) {
    return getFormattedValue(String(value), type);
  }

  let numberDescription = '';
  let greatestDivider = 1;

  Object.values(BIG_NUMBERS).forEach((num) => {
    if (absValue >= num) {
      numberDescription = NUMBER_DESCRIPTIONS[num];
      greatestDivider = num;
    }
  });

  const fractionDigits = value % greatestDivider ? 1 : 0;
  const unit = TRANSFORM_UNITS[type] || '';

  return `${(value / greatestDivider)
    .toFixed(fractionDigits)
    .replace(/\./g, ',')} ${numberDescription}${unit && ` ${unit}`}`;
};

export const formatPostgreSQL = (text: string) =>
  formatDialect(text, {
    dialect: postgresql,
    tabWidth: 4,
  });

export const formatSQL = (text: string) => {
  // Mask variable tokens
  const variableMap: Record<string, string> = {};
  const maskedText = text.replace(/\{@[a-zA-Z0-9_]+\}/g, (match, offset) => {
    const key = `MASKED_VARIABLE_OFFSET_${offset}`;
    variableMap[key] = match;
    return key;
  });

  // Format as PostgreSQL
  // TODO: adapt to data source type dynamically or consolidate as Trino
  const formattedText = formatPostgreSQL(maskedText);

  // Format adjacent '|'-operators as '||'-operator
  const combinedText = formattedText.replace(/\|\s+\|/g, '||');

  // Unmask variable tokens
  const unmaskedText = Object.entries(variableMap).reduce(
    (accumulator, [key, value]) => accumulator.replace(key, value),
    combinedText,
  );

  return unmaskedText;
};
