import { useTranslation } from 'react-i18next';
import {
  InlineAlert,
  InlineAlertSize,
  InlineAlertType,
} from '../../InlineAlert';
import Btn from '../../Btn';
import EditEntityBtn from '../../EditEntityBtn';
import { Structure } from '../../../gql/structure/types';

export enum ErrorComponents {
  ALERT_WITH_CLOSE = 'InlineAlertWithCloseButton',
  ALERT_WITHOUT_EDIT = 'InlineAlertWithoutInlineEdit',
  ALERT_WITH_EDIT = 'InlineAlertWithInlineEdit',
  ALERT_WITH_RELOAD = 'InlineAlertWithReloadButton',
  ALERT_WITHOUT_CLOSE = 'InlineAlertWithoutCloseButton',
}

type ErrorProps = {
  isDA?: boolean;
  errorTitle: string;
  alertCase: ErrorComponents;
  handleReload: () => void;
  handleDelete: () => void;
  editProps?: { id: string; isPreview: boolean };
  testId: string;
  previewWithOrganization?: string;
  previewWithDataSource?: string;
  structureData?: Structure;
};

type InlineAlertProps = Omit<
  ErrorProps,
  'alertCase' | 'handleReload' | 'handleDelete'
> & {
  handleClose?: () => void;
  handleReload?: () => void;
  handleDelete?: () => void;
  previewWithOrganization?: string;
  previewWithDataSource?: string;
  structureData?: Structure;
};

const inlineButtonStyles =
  'focus-visible:ring-2 py-0.75 px-0.5 rounded-md ring-focusBlue text-blueberry-2 underline hover:text-blueberry-3';

export const WidgetError = ({
  errorTitle,
  alertCase,
  handleReload,
  handleDelete,
  editProps,
  isDA,
  testId,
  previewWithDataSource,
  previewWithOrganization,
  structureData,
}: ErrorProps) => {
  const { t } = useTranslation();

  switch (alertCase) {
    case ErrorComponents.ALERT_WITHOUT_EDIT:
      return (
        <InlineAlertWithoutInlineEdit
          testId={`widget-error_${testId}_without-edit`}
          errorTitle={errorTitle ? errorTitle : t('error.widget.load.title')}
          handleReload={handleReload}
          handleDelete={handleDelete}
          isDA={isDA}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
          structureData={structureData}
        />
      );
    case ErrorComponents.ALERT_WITH_EDIT:
      return (
        <InlineAlertWithInlineEdit
          testId={`widget-error_${testId}_with-edit`}
          errorTitle={errorTitle ? errorTitle : t('error.widget.load.title')}
          handleReload={handleReload}
          handleDelete={handleDelete}
          editProps={editProps}
          isDA={isDA}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
          structureData={structureData}
        />
      );
    case ErrorComponents.ALERT_WITH_CLOSE:
      return (
        <InlineAlertWithCloseButton
          testId={`widget-error_${testId}_with-close`}
          errorTitle={errorTitle ? errorTitle : t('error.widget.no-data.title')}
          handleClose={handleDelete}
          isDA={isDA}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
          structureData={structureData}
        />
      );
    case ErrorComponents.ALERT_WITH_RELOAD:
      return (
        <InlineAlertWithReloadButton
          testId={`widget-error_${testId}_with-reload`}
          handleReload={handleReload}
          errorTitle={errorTitle}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
          structureData={structureData}
        />
      );
    default:
      return (
        <InlineAlertWithoutCloseButton
          testId={`widget-error_${testId}_without-close`}
          isDA={isDA}
          errorTitle={errorTitle}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
          structureData={structureData}
        />
      );
  }
};

const ReloadInlineButton = ({
  handleReload,
}: {
  handleReload?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <button
      aria-label="reload"
      title="Reload"
      className={inlineButtonStyles}
      onClick={handleReload}
    >
      {t('error.widget.custom.reload')}
    </button>
  );
};

const DeleteInlineButton = ({
  handleDelete,
}: {
  handleDelete?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <button
      aria-label="delete"
      title="Delete"
      className={inlineButtonStyles}
      onClick={handleDelete}
    >
      {t('error.widget.custom.delete')}
    </button>
  );
};

const InlineAlertWithInlineEdit = ({
  errorTitle,
  editProps,
  handleReload,
  handleDelete,
  isDA,
  testId,
  previewWithDataSource,
  previewWithOrganization,
  structureData,
}: InlineAlertProps) => {
  const { t } = useTranslation();
  return (
    <InlineAlert
      testId={testId}
      title={isDA && errorTitle ? errorTitle : t('error.widget.load.title')}
      size={InlineAlertSize.LARGE}
      type={InlineAlertType.INFO}
      hasGrayBg
    >
      <div className="pt-3 text-base">
        {t('error.widget.custom.customCTA1')}
        <ReloadInlineButton handleReload={handleReload} />
        {t('error.widget.custom.customCTA2')}
        <EditEntityBtn
          widgetId={editProps?.id || ''}
          structureData={structureData}
          asInlineText={true}
          previewWithDataSource={previewWithDataSource}
          previewWithOrganization={previewWithOrganization}
        />
        {t('error.widget.custom.customCTA3')}
        <DeleteInlineButton handleDelete={handleDelete} />
        {isDA ? ' it from the page' : t('error.widget.custom.customCTA4')}
        {!isDA && (
          <a
            className={inlineButtonStyles}
            href="mailto:customer-success@polyteia.de"
          >
            customer-success@polyteia.de
          </a>
        )}
        {t('error.widget.custom.customCTA5')}
      </div>
    </InlineAlert>
  );
};

const InlineAlertWithoutInlineEdit = ({
  isDA,
  errorTitle,
  handleReload,
  handleDelete,
  testId,
}: InlineAlertProps) => {
  const { t } = useTranslation();
  return (
    <InlineAlert
      testId={testId}
      title={errorTitle}
      size={InlineAlertSize.LARGE}
      type={InlineAlertType.INFO}
      hasGrayBg
    >
      <div className="pt-3 text-base">
        {t('error.widget.custom.customCTA1')}
        <ReloadInlineButton handleReload={handleReload} />
        {t('error.widget.custom.customCTA6')}
        <DeleteInlineButton handleDelete={handleDelete} />
        {isDA ? ' it from the page' : t('error.widget.custom.customCTA4')}
        {!isDA && (
          <a
            className={inlineButtonStyles}
            href="mailto:customer-success@polyteia.de"
          >
            customer-success@polyteia.de
          </a>
        )}
        {t('error.widget.custom.customCTA5')}
      </div>
    </InlineAlert>
  );
};

const InlineAlertWithCloseButton = ({
  isDA,
  errorTitle,
  handleClose,
  testId,
}: InlineAlertProps) => {
  const { t } = useTranslation();
  return (
    <InlineAlert
      testId={testId}
      title={errorTitle}
      size={InlineAlertSize.LARGE}
      type={InlineAlertType.INFO}
      hasGrayBg
    >
      {!isDA && (
        <p className="text-base font-[350]">
          {t('error.widget.custom.customCTA8')}
          <a
            className="py-0.75 rounded-md px-0.5 text-blueberry-2 underline ring-focusBlue hover:text-blueberry-3 focus-visible:ring-2"
            href="mailto:customer-success@polyteia.de"
          >
            customer-success@polyteia.de
          </a>
        </p>
      )}
      <div className="pt-3">
        <Btn md onClick={handleClose}>
          {t('common.close')}
        </Btn>
      </div>
    </InlineAlert>
  );
};

const InlineAlertWithoutCloseButton = ({
  isDA,
  testId,
  errorTitle,
}: InlineAlertProps) => {
  const { t } = useTranslation();
  return (
    <InlineAlert
      testId={testId}
      title={errorTitle || t('error.widget.no-data.title')}
      size={InlineAlertSize.LARGE}
      type={InlineAlertType.INFO}
      hasGrayBg
    >
      {isDA ? null : (
        <p className="text-base font-[350]">
          {t('error.widget.custom.customCTA8')}
          <a
            className="py-0.75 rounded-md px-0.5 text-blueberry-2 underline ring-focusBlue hover:text-blueberry-3 focus-visible:ring-2"
            href="mailto:customer-success@polyteia.de"
          >
            customer-success@polyteia.de
          </a>
        </p>
      )}
    </InlineAlert>
  );
};

const InlineAlertWithReloadButton = ({
  handleReload,
  testId,
  errorTitle,
}: InlineAlertProps) => {
  const { t } = useTranslation();
  return (
    <InlineAlert
      testId={testId}
      title={errorTitle || t('error.widget.load.title')}
      size={InlineAlertSize.LARGE}
      type={InlineAlertType.INFO}
      hasGrayBg
    >
      <p className="text-base font-[350]">
        {t('error.widget.load.description')}
        <a
          className="py-0.75 rounded-md px-0.5 text-blueberry-2 underline ring-focusBlue hover:text-blueberry-3 focus-visible:ring-2"
          href="mailto:customer-success@polyteia.de"
        >
          customer-success@polyteia.de
        </a>
      </p>
      <div className="pt-3">
        <Btn md onClick={handleReload}>
          {t('common.reload')}
        </Btn>
      </div>
    </InlineAlert>
  );
};
