import { useApolloClient } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { Classes, Spinner, SpinnerSize } from '@blueprintjs/core';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DangerIcon } from '../../assets/icons/danger.svg';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import QueryPreview from '../../containers/Solutions/Structure/Page/AddWidget/InsightsLibrary/QueryPreview';
import { InsightsDialog } from '../../containers/Solutions/Structure/Page/AddWidget/InsightsLibrary/styles';
import TextWidgetForm from '../../containers/Solutions/Structure/Page/RowSeparator/TextWidgetForm';
import {
  useCurrentLocalPage,
  useEditingContext,
} from '../../containers/Solutions/Structure/editing';
import { HORIZONTAL_ALIGN } from '../../gql/page/types';
import { ENTITY_TYPES } from '../../gql/types';
import { getIsDA } from '../../gql/user/local';
import { BUILD_TEXT_WIDGET } from '../../gql/widget/queries';
import {
  AppliedWidgetFilter,
  DefaultAppliedFilter,
  FILTER_TYPES,
  FilterSection,
  OnFilterChange,
  PageFilter,
  WIDGET_TYPE,
} from '../../gql/widget/types';
import {
  getShouldResetFilters,
  isTotalFilterValue,
  transformRequestFilters,
} from '../../utils/filters';
import useOpen from '../../utils/hooks/useOpen';
import { usePreviewEntity } from '../../utils/preview';
import { PreviewProperties } from '../EditorPanels/Preview';
import ModalLegacy from '../Modal/ModalLegacy';
import SaveBtn from '../SaveBtn';
import { SETTINGS } from '../SimpleFilter/WidgetSettings';
import ToolTip from '../Tooltip';
import WidgetLoader from './WidgetLoader';
import {
  ErrorComponents,
  WidgetError,
} from './components/WidgetErrorComponents';
import { WidgetWrapper } from './styles';
import {
  DATA_PATH_MAP,
  useLiveWidgetVersionId,
  useWidget,
  useWidgetType,
} from './utils';
import BarChart from './widgets/BarChart';
import ChoroplethMap from './widgets/ChoroplethMap';
import GroupedColumnChart from './widgets/GroupedColumnChart';
import LineChart from './widgets/LineChart';
import PieChart from './widgets/PieChart';
import SimpleStatChart from './widgets/SimpleStat';
import StackedColumnChart from './widgets/StackedColumnChart';
import Table from './widgets/Table';
import TextWidget from './widgets/TextWidget';
import { ReactComponent as DeleteIcon } from '../../assets/icons/ui/l/delete.svg';
import { IconButton } from '../IconButton';
import EditEntityBtn from '../EditEntityBtn';
import { ReactComponent as EditIcon } from '../../assets/icons/ui/l/edit.svg';
import { ReactComponent as EmbedIcon } from '../../assets/icons/ui/l/embed.svg';
import { Popover } from '../Popover';
import { EMBED_PREFIX } from '../../app/Routes';
import { useCanEmbed } from '../../utils/hooks/permissions';
import { getIsEmbedded } from '../../containers/Embeddable/utils';

export const WIDGET_CREATE_PREFIX = 'WIDGET_CREATE';
export const WIDGET_UPDATE_PREFIX = 'WIDGET_UPDATE';

export const getIsLocalWidget = (id?: string) =>
  id?.startsWith(WIDGET_CREATE_PREFIX) || id?.startsWith(WIDGET_UPDATE_PREFIX);

const WIDGET_SCENARIO_VALUE_KEY = 'WIDGET_SCENARIO_VALUE_KEY';

export type FilterForPrint = {
  title: string;
  value: any;
};

type Props = {
  id: string;
  structureId: string;
  isStructurePreview: boolean;
  pageFilters: AppliedWidgetFilter[];
  defaultPageFilters: AppliedWidgetFilter[];
  colNum: number;
  setWidgetType?: (type: WIDGET_TYPE) => void;
  horizontalAlign?: HORIZONTAL_ALIGN;
  filtersForPrint: FilterForPrint[];
  inView: boolean;
  setLoadedWidget: (id: string) => void;
  pageScenarioIds?: string[];
  identifier: string;
  rowInd: number;
  colInd: number;
  widgetInd: number;
  pageFilterSections?: FilterSection[];
  defaultFilters?: DefaultAppliedFilter[];
  previewProperties?: PreviewProperties;
};

export const WIDGET_COMPONENTS_MAP = {
  [WIDGET_TYPE.LINE_CHART]: LineChart,
  [WIDGET_TYPE.PIE_CHART]: PieChart,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: StackedColumnChart,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: GroupedColumnChart,
  [WIDGET_TYPE.BAR_CHART]: BarChart,
  [WIDGET_TYPE.CHOROPLETH_MAP]: ChoroplethMap,
  [WIDGET_TYPE.SIMPLE_STAT]: SimpleStatChart,
  [WIDGET_TYPE.TEXT]: TextWidget,
  [WIDGET_TYPE.TABLE]: Table,
};

const Widget: FC<Props> = ({
  structureId,
  id: originalId,
  isStructurePreview,
  pageFilters,
  colNum,
  setWidgetType,
  horizontalAlign,
  filtersForPrint,
  inView,
  setLoadedWidget,
  pageScenarioIds,
  identifier,
  defaultPageFilters,
  rowInd,
  colInd,
  widgetInd,
  pageFilterSections,
  defaultFilters,
  previewProperties,
}) => {
  const { t } = useTranslation();
  const { cache } = useApolloClient();
  const previewWidgetIds = usePreviewEntity(ENTITY_TYPES.WIDGET);
  const [dialogActions, setDialogActions] = useState<JSX.Element | null>(null);

  const [isWidgetChanged, setIsWidgetChanged] = useState(false);

  const {
    open: isTextEdit,
    handleOpen: startTextEdit,
    handleClose: stopTextEdit,
  } = useOpen();

  const {
    open: isWidgetEdit,
    handleOpen: startWidgetEdit,
    handleClose: stopWidgetEdit,
  } = useOpen();

  const {
    open: exitModalOpen,
    handleOpen: handleExitModalOpen,
    handleClose: handleExitModalClose,
  } = useOpen();

  const {
    open: deleteCustomWidgetOpen,
    handleOpen: handleDeleteCustomWidgetOpen,
    handleClose: handleDeleteCustomWidgetClose,
  } = useOpen();

  const [isHovered, setIsHovered] = useState(false);
  const [settingsVal, setSettingsVal] = useState<Record<string, any>>({});
  const widgetRef = useRef<HTMLDivElement>(null);
  const { isCustomerEditorModeEnabled, updatePageConfig, savedStructure } =
    useEditingContext();
  const localPage = useCurrentLocalPage();

  const isDA = getIsDA();
  const allowCustomerEdits = localPage?.page?.pageConfig?.allowCustomerEdits;
  const isEditing = isCustomerEditorModeEnabled && (allowCustomerEdits || isDA);

  const id = previewWidgetIds?.[originalId as string] || originalId;
  const isPreview = !!previewWidgetIds?.[originalId as string];
  const isEmbedded = getIsEmbedded();

  const { loading: embedPermissionsLoading, canEmbed } = useCanEmbed();

  const showEmbed = canEmbed && !isEmbedded;

  const [widgetFilters, setWidgetFilters] = useState<
    Array<AppliedWidgetFilter & { title: string }>
  >(
    (isEditing
      ? localPage?.page?.pageConfig?.defaultFilters?.find(
          (filter) => filter.widgetId === id,
        )?.filters
      : defaultFilters
    )?.map(({ filterId, value, multiValue, title, type }) => ({
      filterId,
      value,
      multiValue,
      title: title ?? '-',
      type:
        type ??
        (value ? FILTER_TYPES.DROPDOWN : FILTER_TYPES.MULTISELECT_DROPDOWN),
    })) || [],
  );

  const initialWidgetFiltersRef = useRef(widgetFilters);

  const isLocalWidget = getIsLocalWidget(id);

  const skipLiveWidgetVersionIdQuery =
    !isEmbedded ||
    !inView ||
    isPreview ||
    !(isStructurePreview || isCustomerEditorModeEnabled) ||
    isLocalWidget;

  const versionId = useLiveWidgetVersionId(id, skipLiveWidgetVersionIdQuery);

  const {
    widgetType,
    loading: widgetTypeLoading,
    refetch: refetchWidgetType,
    error: widgetTypeError,
  } = useWidgetType(id, isPreview, inView, isLocalWidget);

  useEffect(() => {
    if (widgetType) {
      setWidgetType?.(widgetType);
    }
  }, [setWidgetType, widgetType]);

  const options = {
    variables: {
      source: {
        widgetId: isStructurePreview && versionId ? versionId : id,
        appliedFilters: transformRequestFilters(widgetFilters),
        appliedPageFilters: transformRequestFilters(pageFilters),
        structureId: isCustomerEditorModeEnabled
          ? savedStructure?.versionId || structureId
          : structureId,
        organizationId: previewProperties?.organizationId,
        dataSourceId: previewProperties?.dataSourceId,
      },
    },
  };

  const defaultOptionsRef = useRef(cloneDeep(options));

  const {
    data: widgetBuildData,
    loading: buildLoading,
    error,
    refetch,
  } = useWidget(
    options,
    widgetType,
    isPreview,
    isStructurePreview || isCustomerEditorModeEnabled,
    inView,
    !options.variables.source.structureId,
  );

  let buildData = widgetBuildData;

  if (!buildData && isLocalWidget && widgetType === WIDGET_TYPE.TEXT) {
    const dump: any = cache.readQuery({
      query: BUILD_TEXT_WIDGET,
      variables: {
        source: {
          appliedFilters: [],
          appliedPageFilters: [],
          structureId: savedStructure?.versionId,
          widgetId: id,
        },
      },
    });

    buildData = dump?.buildTextWidget;
  }

  const builtWidgetData = buildData?.widgetConfig;

  useEffect(() => {
    setDialogActions(
      <div className="u-display-space-between">
        <div className="u-margin-right-10 d-flex flex-column">
          <span className="title-text">{builtWidgetData?.title}</span>
        </div>
      </div>,
    );
  }, [builtWidgetData?.title]);

  const scenarioIds = useMemo(() => {
    return [
      ...(builtWidgetData?.filterSections
        ?.filter?.((section: FilterSection) => section?.keepDefault)
        .flatMap((section: FilterSection) =>
          section?.filters?.map(({ id }) => id),
        ) || []),
      ...(pageScenarioIds?.filter((id) =>
        builtWidgetData?.pageFilters?.some(
          (filter: PageFilter) => filter?.id === id,
        ),
      ) || []),
    ];
  }, [
    pageScenarioIds,
    builtWidgetData?.filterSections,
    builtWidgetData?.pageFilters,
  ]);

  const isScenarios = scenarioIds.length;

  const noScenariosVariables = useMemo(
    () =>
      isScenarios
        ? {
            source: {
              ...options.variables.source,
              appliedFilters: transformRequestFilters(
                widgetFilters.filter(
                  (filter) => !scenarioIds.includes(filter?.filterId),
                ),
              ),
              appliedPageFilters: transformRequestFilters([
                ...pageFilters.filter(
                  (filter) => !scenarioIds?.includes(filter?.filterId),
                ),
                ...defaultPageFilters.filter((filter) =>
                  scenarioIds?.includes(filter?.filterId),
                ),
              ]),
            },
          }
        : {},
    [
      defaultPageFilters,
      isScenarios,
      options.variables.source,
      pageFilters,
      scenarioIds,
      widgetFilters,
    ],
  );

  const { data: buildNoScenariosData, loading: noScenariosBuildLoading } =
    useWidget(
      { ...options, variables: noScenariosVariables },
      widgetType,
      isPreview,
      isStructurePreview,
      inView,
      !isScenarios,
    );

  const noScenariosData = buildNoScenariosData?.widgetConfig;

  const updateDefaultFilters = useCallback(
    (
      newWidgetFilters: {
        filterId: string;
        value: string | string[];
        title: string;
        type: FILTER_TYPES;
      }[],
      shouldResetIds: string[],
    ) => {
      const defaultFilters = localPage?.page?.pageConfig?.defaultFilters || [];
      const defaultWidgetFilter = defaultFilters.find(
        (filter) => filter.widgetId === id,
      ) || { widgetId: id };
      defaultWidgetFilter.filters = [
        ...(defaultWidgetFilter.filters?.filter(
          (filter) => !shouldResetIds.includes(filter.filterId),
        ) || []),
        ...newWidgetFilters.map(({ filterId, value, title, type }) =>
          Array.isArray(value)
            ? { filterId, multiValue: value, title, type }
            : { filterId, value, title, type },
        ),
      ];

      updatePageConfig(
        {
          defaultFilters: [
            ...defaultFilters.filter((filter) => filter.widgetId !== id),
            defaultWidgetFilter,
          ],
        },
        localPage?.page?.id,
      );
    },
    [
      id,
      localPage?.page?.id,
      localPage?.page?.pageConfig?.defaultFilters,
      updatePageConfig,
    ],
  );

  const applyFilters: OnFilterChange = useCallback(
    (filters) => {
      const newWidgetFilters = filters
        .filter(({ value }) => {
          if (typeof value === 'object') {
            return value?.length;
          }
          return value !== undefined && value !== null;
        })
        .map(({ id, value, type, title }) => ({
          filterId: id,
          value,
          title,
          type: type || FILTER_TYPES.DROPDOWN,
        }));

      setWidgetFilters((currentFilters) => {
        const shouldResetIds = getShouldResetFilters(
          builtWidgetData?.widgetFilters,
          currentFilters,
          newWidgetFilters,
        );

        const remainingFilters = newWidgetFilters.filter(
          ({ filterId }) => !shouldResetIds.includes(filterId),
        );

        if (isEditing) {
          updateDefaultFilters(
            remainingFilters,
            newWidgetFilters.map((filter) => filter.filterId),
          );
        }

        return remainingFilters;
      });

      filters
        .filter(({ id }) => scenarioIds.includes(id))
        .forEach((filter) => {
          const previousFilters = JSON.parse(
            localStorage.getItem(WIDGET_SCENARIO_VALUE_KEY) || '{}',
          );

          previousFilters[`${identifier}-${filter.id}`] = filter.value;

          localStorage.setItem(
            WIDGET_SCENARIO_VALUE_KEY,
            JSON.stringify(previousFilters),
          );
        });
    },
    [
      identifier,
      scenarioIds,
      builtWidgetData?.widgetFilters,
      isEditing,
      updateDefaultFilters,
    ],
  );

  useEffect(() => {
    setWidgetFilters((prev) => {
      if (prev?.length) {
        return prev;
      }

      const savedFilters = localStorage.getItem(WIDGET_SCENARIO_VALUE_KEY);

      if (savedFilters) {
        const filters = JSON.parse(savedFilters) as Record<any, any>;

        const hydratedFilters = Object.entries(filters)
          .filter(([key]) => key.startsWith(identifier))
          .map(([key, value]) => {
            const ids = key.split('-');
            const filterId = ids[ids.length - 1];

            return {
              filterId,
              title: value,
              value,
              type: FILTER_TYPES.SLIDER,
            };
          });

        return hydratedFilters;
      }

      return prev;
    });
  }, [identifier]);

  const loading =
    widgetTypeLoading ||
    buildLoading ||
    noScenariosBuildLoading ||
    embedPermissionsLoading;

  useEffect(() => {
    if ((builtWidgetData && widgetType && !loading) || error) {
      setTimeout(() => setLoadedWidget(originalId), 300);
    }
  }, [
    error,
    loading,
    originalId,
    setLoadedWidget,
    builtWidgetData,
    widgetType,
  ]);

  useEffect(() => {
    if (!isCustomerEditorModeEnabled) {
      stopTextEdit();
    }
  }, [isCustomerEditorModeEnabled, stopTextEdit]);

  const removeWidget = useCallback(() => {
    const layoutCopy = localPage?.page?.pageConfig?.layout || [];

    updatePageConfig(
      {
        layout: [
          ...layoutCopy.slice(0, rowInd),
          {
            ...layoutCopy[rowInd],
            columns: [
              ...layoutCopy[rowInd].columns.slice(0, colInd),
              {
                ...layoutCopy[rowInd].columns[colInd],
                elements: layoutCopy[rowInd].columns[colInd].elements.filter(
                  (_, ind) => ind !== widgetInd,
                ),
              },
              ...layoutCopy[rowInd].columns.slice(colInd + 1),
            ],
          },
          ...layoutCopy.slice(rowInd + 1),
        ],
      },
      localPage?.page?.id,
    );
  }, [
    colInd,
    localPage?.page?.id,
    localPage?.page?.pageConfig?.layout,
    rowInd,
    updatePageConfig,
    widgetInd,
  ]);

  // Update stored filter title based on retrieved values
  useEffect(() => {
    widgetFilters.forEach((filter) => {
      const appliedFilter = builtWidgetData?.widgetFilters?.find(
        (config: any) => config?.id === filter.filterId,
      );
      if (!appliedFilter) {
        return;
      }
      if (isTotalFilterValue(filter)) {
        filter.title = appliedFilter?.totalLabel ?? filter.title;
      } else {
        const index = appliedFilter?.values?.indexOf(filter.value);
        index !== -1 &&
          (filter.title = appliedFilter?.labels?.[index] ?? filter.title);
      }
    });
  }, [widgetFilters, builtWidgetData?.widgetFilters]);

  const handleReload = () => {
    setWidgetFilters(initialWidgetFiltersRef.current);

    if (!widgetType) {
      refetchWidgetType();
      return;
    }
    refetch(defaultOptionsRef.current.variables);
  };

  const widgetData = builtWidgetData || (isScenarios && noScenariosData);

  const showAlert =
    !widgetType || !widgetData || widgetTypeError || error || !builtWidgetData;

  // as a customer you are not allowed to edit from inside the widget error
  const canEdit = id && isDA;

  if (loading && !widgetData) {
    return <WidgetLoader />;
  }

  const getAlertCase = () => {
    if (!widgetType && !canEdit) {
      if (isDA) {
        return ErrorComponents.ALERT_WITHOUT_EDIT;
      }
      if (isCustomerEditorModeEnabled) {
        return ErrorComponents.ALERT_WITH_CLOSE;
      }
      return ErrorComponents.ALERT_WITHOUT_CLOSE;
    }
    if (
      (!isDA && isCustomerEditorModeEnabled && widgetType) ||
      (canEdit && !widgetType)
    ) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    if (
      (isCustomerEditorModeEnabled && widgetType && canEdit) ||
      (isDA && widgetType)
    ) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if (!canEdit && !isCustomerEditorModeEnabled && widgetType) {
      return ErrorComponents.ALERT_WITH_RELOAD;
    }
    if (!isCustomerEditorModeEnabled && !isDA && !widgetType) {
      return ErrorComponents.ALERT_WITHOUT_CLOSE;
    }
    if (isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    return ErrorComponents.ALERT_WITHOUT_CLOSE;
  };

  const editProps = { id, isPreview };

  const errorType =
    !widgetType && !widgetData
      ? 'Widget id not found'
      : widgetType && !widgetData
      ? `No data for this ${widgetType} widget`
      : '';

  const testId =
    !widgetType && !widgetData
      ? 'id-not-found'
      : widgetType && !widgetData
      ? 'no-widget-data'
      : 'other';
  if (showAlert) {
    return (
      <WidgetError
        testId={testId}
        isDA={isDA}
        errorTitle={isDA ? errorType : ''}
        alertCase={getAlertCase()}
        handleReload={handleReload}
        handleDelete={removeWidget}
        editProps={editProps}
        previewWithDataSource={options?.variables?.source?.dataSourceId ?? ''}
        previewWithOrganization={
          options?.variables?.source?.organizationId ?? ''
        }
        structureData={savedStructure}
      />
    );
  }
  const visualType = (settingsVal[SETTINGS.WIDGET_TYPE] ||
    widgetType) as WIDGET_TYPE;

  const WidgetComponent = WIDGET_COMPONENTS_MAP[visualType];

  const getLabel = (label: keyof FilterSection): string =>
    builtWidgetData?.filterSections?.[0]?.[label] ||
    pageFilterSections?.[0]?.[label];

  const embeddableURL = `${window.location.protocol}//${
    window.location.host
  }/${EMBED_PREFIX}/widget/${savedStructure?.id as string}/${id}`;

  return (
    <>
      <Draggable
        draggableId={`${id}|${identifier}`}
        index={widgetInd}
        isDragDisabled={!isEditing || isTextEdit}
      >
        {(provided, snapshot) => {
          return (
            <div
              className={`venus-widget-wrapper u-position-relative`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {loading && (
                <Spinner
                  size={SpinnerSize.LARGE}
                  className="widget-filter-loading-spinner"
                />
              )}
              <WidgetWrapper
                ref={widgetRef}
                className={`venus-widget ${
                  loading ? 'widget-filter-loading' : ''
                }`}
                isDragging={snapshot.isDragging}
                {...(widgetType === WIDGET_TYPE.SIMPLE_STAT && {
                  horizontalAlign,
                })}
              >
                {!isTextEdit && (
                  <WidgetComponent
                    data={{
                      ...builtWidgetData,
                      filterSections: [
                        ...(builtWidgetData?.filterSections || []),
                        {
                          defaultLabel: getLabel('defaultLabel'),
                          filteredLabel: getLabel('filteredLabel'),
                        },
                      ],
                    }}
                    isCreatedByCustomer={buildData?.isCreatedByCustomer}
                    editProps={
                      canEdit ? { id: id, isPreview: isPreview } : undefined
                    }
                    handleReload={handleReload}
                    handleDelete={removeWidget}
                    creatorFullName={buildData?.creatorFullName}
                    createdAt={buildData?.createdAt}
                    isEditing={isEditing}
                    noScenariosData={noScenariosData?.data}
                    onFilterChange={applyFilters}
                    filtersVal={widgetFilters.map(
                      ({ filterId, value, multiValue, title, type }) => {
                        return {
                          id: filterId,
                          value: multiValue ?? value,
                          title,
                          type: type || FILTER_TYPES.DROPDOWN,
                        };
                      },
                    )}
                    afterTitle={
                      <>
                        {!isTextEdit && (
                          <SaveBtn
                            widgetData={builtWidgetData}
                            noScenariosData={noScenariosData}
                            getEl={() => widgetRef.current}
                            title={builtWidgetData?.title}
                            entityType={ENTITY_TYPES.WIDGET}
                            show={isHovered}
                            widgetType={widgetType}
                            colNum={colNum}
                            filtersForPrint={filtersForPrint}
                            visibleData={settingsVal?.VISIBLE_DATA}
                          />
                        )}
                        {!isEmbedded &&
                          (isDA ? (
                            <EditEntityBtn
                              widgetId={id}
                              structureData={savedStructure}
                              previewWithDataSource={
                                options?.variables?.source?.dataSourceId
                              }
                              previewWithOrganization={
                                options?.variables?.source?.organizationId
                              }
                            />
                          ) : (
                            isCustomerEditorModeEnabled &&
                            (buildData.isCreatedByCustomer ? (
                              widgetType === WIDGET_TYPE.TEXT ? (
                                <IconButton
                                  ariaLabel={t('common.edit')}
                                  onClick={startTextEdit}
                                  icon={EditIcon}
                                />
                              ) : (
                                <EditEntityBtn
                                  widgetId={id}
                                  structureData={savedStructure}
                                  previewWithDataSource={
                                    options?.variables?.source?.dataSourceId
                                  }
                                  previewWithOrganization={
                                    options?.variables?.source?.organizationId
                                  }
                                />
                              )
                            ) : (
                              <div className="lock-icon-wrapper edit-entity-btn u-padding-0 u-has-transition u-opacity-0 mx-1">
                                <ToolTip holder={<Lock />} position={'top'}>
                                  <div className="lock-tooltip">
                                    {t('hint.edit-restriction.diagrams')}
                                  </div>
                                </ToolTip>
                              </div>
                            ))
                          ))}

                        {showEmbed && (
                          <Popover on="click" text={embeddableURL}>
                            <IconButton
                              ariaLabel={t('common.embed')}
                              icon={EmbedIcon}
                              onClick={() => {}}
                            />
                          </Popover>
                        )}
                        {isEditing && (
                          <IconButton
                            ariaLabel={t('common.delete')}
                            icon={DeleteIcon}
                            onClick={
                              buildData?.isCreatedByCustomer
                                ? handleDeleteCustomWidgetOpen
                                : removeWidget
                            }
                          />
                        )}
                      </>
                    }
                    settings={
                      !isCustomerEditorModeEnabled
                        ? [
                            {
                              id: SETTINGS.SHOW_SETTINGS,
                            },
                            {
                              id: SETTINGS.SHOW_VALUE,
                            },
                            {
                              id: SETTINGS.WIDGET_TYPE,
                              initialValue: visualType,
                            },
                            {
                              id: SETTINGS.VISIBLE_DATA,
                            },
                            ...(widgetType === WIDGET_TYPE.CHOROPLETH_MAP
                              ? [
                                  {
                                    id: SETTINGS.MAP_BG,
                                  },
                                ]
                              : []),
                          ]
                        : []
                    }
                    settingsVal={settingsVal}
                    setSettingsVal={setSettingsVal}
                  />
                )}
                {isTextEdit && (
                  <TextWidgetForm
                    id={id}
                    versionId={builtWidgetData?.versionId}
                    handleClose={stopTextEdit}
                    data={builtWidgetData}
                    rowIndex={rowInd}
                    colInd={colInd}
                    widgetInd={widgetInd}
                  />
                )}
              </WidgetWrapper>
            </div>
          );
        }}
      </Draggable>
      {builtWidgetData && widgetType && (
        <InsightsDialog
          isOpen={isWidgetEdit}
          onClose={isWidgetChanged ? handleExitModalOpen : stopWidgetEdit}
          title={dialogActions}
        >
          <div className={Classes.DIALOG_BODY}>
            <QueryPreview
              queryId={builtWidgetData?.queryId}
              handleClose={stopWidgetEdit}
              handleConfirm={stopWidgetEdit}
              setIsCreatingWidget={() => undefined}
              isCreatingWidget
              rowInd={rowInd}
              colInd={colInd}
              existingConfig={builtWidgetData}
              versionId={versionId}
              refetchWidget={async () => {
                await refetch();

                cache.evict({
                  id: 'ROOT_QUERY',
                  fieldName: `widgetLiveTypeById({"id":"${id}"})`,
                });
                cache.evict({
                  id: 'ROOT_QUERY',
                  fieldName: `${DATA_PATH_MAP[widgetType]}({"source":{"appliedFilters":[],"appliedPageFilters":[],"structureId":"${savedStructure?.id}","widgetId":"${originalId}"}})`,
                });
                cache.gc();
              }}
              onChange={() => setIsWidgetChanged(true)}
              setDialogActions={setDialogActions}
            />
          </div>
        </InsightsDialog>
      )}
      <ModalLegacy
        title={<DangerIcon />}
        content={
          <p style={{ width: 475 }}>
            Sind Sie sicher, dass Sie den Diagrammeditor schließen wollen?
            <br />
            Alle Ihre bisherigen Einstellungen gehen verloren.
          </p>
        }
        confirmButtonText="Verlassen"
        cancelButtonText="Abbrechen"
        intent="danger"
        isOpen={exitModalOpen}
        onCancel={handleExitModalClose}
        onConfirm={() => {
          handleExitModalClose();
          stopWidgetEdit();
          setIsWidgetChanged(false);
        }}
      />
      <ModalLegacy
        title={`${builtWidgetData?.title} löschen`}
        content={
          <p style={{ width: 475 }}>
            Sind Sie sicher, dass Sie diesen {builtWidgetData?.title} löschen
            möchten?
          </p>
        }
        confirmButtonText="Löschen"
        cancelButtonText="Abbrechen"
        intent="danger"
        isOpen={deleteCustomWidgetOpen}
        onCancel={handleDeleteCustomWidgetClose}
        onConfirm={() => {
          removeWidget();
          handleDeleteCustomWidgetClose();
        }}
      />
    </>
  );
};

export default Widget;
