import React, { FC } from 'react';
import { ReactComponent as InfoPointGray } from '../../assets/icons/info-point-gray.svg';
import { WidgetStand } from '../../gql/widget/types';
import { getIsEmbedded } from '../../containers/Embeddable/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  stand?: WidgetStand;
}

const GraphStand: FC<Props> = ({ stand }) => {
  const isQuery = stand?.query && stand?.dateColumn;
  const { t } = useTranslation();

  // when embedding, we're adding one more line to the Stand/Quelle
  // the additional line influences positions of the entire block and the elements within it
  const isEmbedded = getIsEmbedded();
  const marginTopMainDiv = isEmbedded ? '5px' : '26px';
  const marginTopInfoIcon = isEmbedded ? '13px' : '0';

  if (!stand?.source && !isQuery) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: marginTopMainDiv,
        color: '#cacbcb',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      {stand?.source && (
        <div className="u-position-relative u-margin-right-5">
          {`${t('stand.source')}${stand.source}`}
          <div
            className="u-position-absolute"
            style={{ top: marginTopInfoIcon, left: '-23px' }}
          >
            <InfoPointGray />
          </div>
        </div>
      )}
      {isQuery && (
        <div className="u-position-relative">
          {`${t('stand.stand')}${stand.dateColumn}`}
          {!stand.source && (
            <div
              className="u-position-absolute"
              style={{ top: marginTopInfoIcon, left: '-23px' }}
            >
              <InfoPointGray />
            </div>
          )}
        </div>
      )}
      {isEmbedded && (
        <div className="u-position-absolute">
          <br />
          {t('stand.powered-by')}
          <a href="https://polyteia.de">Polyteia</a>
        </div>
      )}
    </div>
  );
};

export default GraphStand;
