import { ResizeSensor2 } from '@blueprintjs/popover2';
import { Bar, BarTooltipProps } from '@nivo/bar';
import { CartesianMarkerProps } from '@nivo/core';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsDA } from '../../../../gql/user/local';
import {
  BuiltWidgetConfig,
  CONFIG_ELEMENT_VALUES_TYPE,
  OnFilterChange,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { colors } from '../../../../utils/colors';
import { WIDGET_BREAKPOINTS } from '../../../../utils/common';
import { useFilters } from '../../../../utils/filters';
import { getFormattedValue } from '../../../../utils/format';
import GraphStand from '../../../GraphStand';
import SimpleFilter, { FilterSelection } from '../../../SimpleFilter';
import { SETTINGS } from '../../../SimpleFilter/WidgetSettings';
import { getLabelValues } from '../../../Slider';
import AggregationFooter from '../../components/AggregationFooter';
import CrossOverContent from '../../components/CrossOverContent';
import GraphLegend from '../../components/GraphLegend';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import {
  Y_AXIS_TICK_PADDING,
  Y_AXIS_TITLE,
  Y_AXIS_TITLE_PADDING,
} from '../../utils/constants';
import { useHoverHeaders, useHoversFromData } from '../../utils/hovers';
import BarValueLabels from '../../utils/layers/BarValueLabels';
import BarTotals from '../../utils/layers/BarValueLabels/BarTotals';
import DomainHighlight from '../../utils/layers/DomainHighlight';
import { NIVO_THEME } from '../../utils/nivoTheme';
import { useSeparators } from '../../utils/separators';
import useGraphSeries, {
  findStackedRange,
} from '../../utils/useFormatGraphSeries/useGraphSeries';
import usePalette from '../../utils/usePalette';
import useScale from '../../utils/useScale';
import { generateUniqueTicks } from '../../utils';
import { getIsEmbedded } from '../../../../containers/Embeddable/utils';

const X_AXIS_TICK_PADDING_BAR = 10;
const X_AXIS_TITLE_PADDING_BAR = 15;
const X_AXIS_TITLE_BAR = 10 + X_AXIS_TITLE_PADDING_BAR;

type IBar = Record<number, string> & { id: string };

type Props = {
  data: BuiltWidgetConfig;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
  isColumn?: boolean;
  stacked?: boolean;
} & WidgetCommonProps;

const BarChart: FC<Props> = ({
  data,
  onFilterChange,
  filtersVal,
  settingsVal,
  setSettingsVal,
  settings,
  afterTitle,
  isColumn,
  stacked,
  editProps,
  handleDelete,
  handleReload,
  isEditing,
  isCreatedByCustomer,
}) => {
  const [selectedData, setSelectedData] = useState<number | null>(null);
  const [width, setWidth] = useState(0);

  const sliderContainerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const filters = useFilters(data.widgetFilters);
  const hoverHeaders = useHoverHeaders(data);
  const separators = useSeparators(data);

  const isEmbedded = getIsEmbedded();
  const widgetHeight = isEmbedded ? window.innerHeight - 150 : 300;

  const domainConfig = data.domains.find(({ type }) => type === 'xAxis');
  const rangeConfig = data.domains.find(({ type }) => type === 'yAxis');

  const domainVals = generateUniqueTicks(
    domainConfig?.values || [],
    domainConfig?.valuesType,
    domainConfig?.decimalPlaces,
  ).map((element) => (element ? element.toString() : '-'));

  const originalDataSeries = useMemo(() => data.data || [], [data.data]);

  const dataSeries = useMemo(
    () =>
      originalDataSeries.filter(
        (_, ind) => settingsVal?.[SETTINGS.VISIBLE_DATA]?.includes(ind) ?? true,
      ),
    [originalDataSeries, settingsVal],
  );
  const { t } = useTranslation();
  const originalBars: IBar[] = useMemo(
    () =>
      domainVals.map((point, domainInd) => {
        return originalDataSeries.reduce(
          (acc, { values }, dataInd) => {
            return {
              ...acc,
              [dataInd]: values[domainInd],
            };
          },
          { id: point },
        );
      }),
    [originalDataSeries, domainVals],
  );

  const originalPalette = usePalette(originalDataSeries, selectedData);

  const hoverSeries = useHoversFromData(
    data,
    settingsVal?.[SETTINGS.VISIBLE_DATA],
  );

  const [bars, barKeys, palette] = useMemo(() => {
    const barKeys: string[] = [];
    const palette: Record<number, any> = {};

    const filteredBars = originalBars.map((bar) => {
      const newBar: IBar = { id: bar.id };

      (
        (settingsVal?.[SETTINGS.VISIBLE_DATA] as number[])?.sort(
          (a, b) => a - b,
        ) || Object.keys(originalDataSeries)
      ).forEach((dataInd) => {
        const d = bar[dataInd];

        if (d !== null) {
          const strKey = String(dataInd);

          if (!barKeys.includes(strKey)) {
            barKeys.push(strKey);
          }

          palette[dataInd] = originalPalette[dataInd];

          newBar[dataInd] = d;
        }
      });

      return newBar;
    });

    return [
      filteredBars,
      barKeys.sort((a, b) => +a - +b),
      Object.values(palette),
    ];
  }, [originalDataSeries, originalBars, originalPalette, settingsVal]);

  const realValuesRange = useGraphSeries(
    originalDataSeries,
    stacked ? findStackedRange : undefined,
  );

  const { domain: visibleValuesRange, ticks } = useScale({
    min: realValuesRange[0],
    max: realValuesRange[1],
    zeroBased: stacked || data.zeroBased,
    divByTen:
      realValuesRange[1] - realValuesRange[0] > 15 ||
      realValuesRange[0] === realValuesRange[1],
    tickTotal: 8,
    maxValue: rangeConfig?.maxValue,
    regulator: realValuesRange[1] * 0.1,
  });

  let [min, max] = visibleValuesRange;

  const rangeVals = generateUniqueTicks(
    ticks,
    rangeConfig?.valuesType,
    rangeConfig?.decimalPlaces,
  );

  const newMax = Number(
    rangeVals.reduce(
      (accumulator, value) => (value > accumulator ? value : accumulator),
      max,
    ),
  );

  max = newMax > max ? newMax : max;

  const isSm = width && width <= WIDGET_BREAKPOINTS.SM;

  const tiltX = (isColumn ? domainConfig : rangeConfig ?? domainConfig)
    ?.tiltLabels;

  const domainTickValues = useMemo(() => {
    if (domainConfig?.showAllLabels) {
      return domainVals;
    }

    if (isColumn) {
      return (isSm && domainVals.length) || domainVals.length > 25
        ? getLabelValues(domainVals.length - 1, width, 5, 5).map(
            (ind) => domainVals[ind],
          )
        : domainVals;
    }

    return domainVals.length > 20
      ? getLabelValues(domainVals.length - 1, 0, 10, 0).map(
          (ind) => domainVals[ind],
        )
      : domainVals;
  }, [domainConfig?.showAllLabels, domainVals, isColumn, isSm, width]);

  const formatDomain = useCallback(
    (value: number | string) =>
      getFormattedValue(
        value,
        domainConfig?.valuesType,
        domainConfig?.decimalPlaces,
      ),
    [domainConfig],
  );

  const formatRange = useCallback(
    (value: number | string) =>
      getFormattedValue(
        value,
        rangeConfig?.valuesType,
        rangeConfig?.decimalPlaces,
      ),
    [rangeConfig],
  );

  const formatData = useCallback(
    (value: number | string) =>
      getFormattedValue(
        value,
        domainConfig?.valuesType,
        domainConfig?.decimalPlaces,
      ),
    [domainConfig],
  );

  const DomainTicksSize = useMemo(() => {
    if (isColumn && !tiltX) {
      return 15;
    }

    const maxLength = domainTickValues
      .map((val) => String(val).trim())
      .reduce((acc, tick) => {
        const l = formatDomain(tick).length;

        return l > acc ? l : acc;
      }, 0);

    return maxLength * 6;
  }, [domainTickValues, formatDomain, isColumn, tiltX]);

  const RangeTicksSize = useMemo(() => {
    if (!isColumn && !tiltX) {
      return 15;
    }

    const maxLength = rangeVals.reduce((acc: number, tick) => {
      const l = formatRange(tick).length;

      return l > acc ? l : acc;
    }, 0);

    return maxLength * 6;
  }, [isColumn, tiltX, rangeVals, formatRange]);

  const XTickSize = isColumn ? DomainTicksSize : RangeTicksSize;
  const XTitle = (isColumn ? domainConfig : rangeConfig)?.title;
  const XFormat = isColumn ? formatDomain : formatRange;
  const XTicks = isColumn ? domainTickValues : rangeVals;

  const YTickSize = isColumn ? RangeTicksSize : DomainTicksSize;
  const YTitle = (isColumn ? rangeConfig : domainConfig)?.title;
  const YFormat = isColumn ? formatRange : formatDomain;
  const YTicks = isColumn ? rangeVals : domainTickValues;

  const isEmpty = useMemo(
    () =>
      originalDataSeries.flatMap((s) => s.values).every((val) => val === null),
    [originalDataSeries],
  );

  const markers: CartesianMarkerProps[] = useMemo(
    () =>
      separators.flatMap(({ label, x }) => {
        return label.split('\n').map((line, ind) => {
          const horizontalValue = x ? x - 1 : 0;

          return {
            axis: isColumn ? 'x' : 'y',
            value: domainVals[isColumn ? x : horizontalValue],
            lineStyle: {
              strokeDasharray: '5, 8',
            },
            legend: (
              <tspan x={isColumn ? '0' : '10'} dy={ind * 12}>
                {line}
              </tspan>
            ) as unknown as string,
            textStyle: {
              fontSize: '12px',
              fill: colors.monochromatic2,
              dominantBaseline: 'ideographic',
            },
          };
        });
      }),
    [domainVals, isColumn, separators],
  );

  const layers = useMemo(
    () => [
      DomainHighlight(
        domainConfig?.highlights || [],
        domainVals,
        !isColumn,
        barKeys.length,
        stacked,
      ) as any,
      'grid',
      'axes',
      'bars',
      'markers',
      BarValueLabels(
        originalDataSeries,
        selectedData,
        settingsVal?.[SETTINGS.SHOW_VALUE] || false,
        isColumn,
        stacked,
      ),
      ...(stacked
        ? [
            BarTotals(
              settingsVal?.[SETTINGS.SHOW_VALUE] || false,
              rangeConfig?.valuesType,
            ),
          ]
        : []),
    ],
    [
      barKeys.length,
      domainConfig?.highlights,
      domainVals,
      isColumn,
      originalDataSeries,
      rangeConfig?.valuesType,
      selectedData,
      settingsVal,
      stacked,
    ],
  );

  const margin = {
    top: 5,
    right: 30,
    bottom:
      X_AXIS_TICK_PADDING_BAR + XTickSize + (XTitle ? X_AXIS_TITLE_BAR : 0),
    left: Y_AXIS_TICK_PADDING + YTickSize + (YTitle ? Y_AXIS_TITLE : 5),
  };

  const isColRound =
    isColumn &&
    domainVals.length > 200 &&
    (!width || width - margin.left - margin.right > domainVals.length);

  const isBarRound =
    !isColumn &&
    domainVals.length > 100 &&
    (!width || widgetHeight - margin.top - margin.bottom > domainVals.length);

  const isDA = getIsDA();

  const getAlertCase = () => {
    if (editProps && isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if (!isDA && isEditing && isCreatedByCustomer) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    return ErrorComponents.ALERT_WITH_RELOAD;
  };
  return (
    <ResizeSensor2
      onResize={(entries) => {
        setWidth(entries[0].contentRect.width);
      }}
    >
      <div className="widget">
        <SimpleFilter
          header={data.title}
          info={data.info}
          filters={filters}
          onFilterSelect={onFilterChange}
          className="u-margin-bottom-40"
          value={filtersVal}
          afterTitle={afterTitle}
          sliderContainerRef={sliderContainerRef}
          settings={settings}
          settingsVal={settingsVal}
          setSettingsVal={setSettingsVal}
          data_series={originalDataSeries}
        />
        <div className="chart-container bar-container">
          {isEmpty ? (
            <WidgetError
              testId={'incomplete-data'}
              isDA={isDA}
              errorTitle={isDA ? t('error.widget.filter.title') : ''}
              alertCase={getAlertCase()}
              handleReload={handleReload ? handleReload : () => {}}
              handleDelete={handleDelete ? handleDelete : () => {}}
              editProps={
                editProps?.id ? editProps : { id: '', isPreview: false }
              }
            />
          ) : (
            <Bar
              theme={NIVO_THEME}
              height={widgetHeight}
              width={width}
              data={bars}
              keys={barKeys}
              margin={margin}
              padding={0.15}
              indexScale={{ type: 'band', round: isColRound || isBarRound }}
              valueScale={{
                type: 'linear',
                clamp: !stacked,
              }}
              minValue={min}
              maxValue={max}
              groupMode={stacked ? 'stacked' : 'grouped'}
              layout={isColumn ? 'vertical' : 'horizontal'}
              colors={palette}
              valueFormat={formatData}
              axisBottom={{
                tickSize: 0,
                tickPadding: X_AXIS_TICK_PADDING_BAR,
                tickRotation: tiltX ? -50 : 0,
                tickValues: XTicks,
                legend: XTitle,
                legendOffset:
                  X_AXIS_TICK_PADDING_BAR +
                  XTickSize +
                  X_AXIS_TITLE_PADDING_BAR,
                legendPosition: 'middle',
                format: XFormat,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: Y_AXIS_TICK_PADDING,
                tickValues: YTicks,
                legend: YTitle,
                legendOffset: -(
                  Y_AXIS_TICK_PADDING +
                  YTickSize +
                  Y_AXIS_TITLE_PADDING
                ),
                legendPosition: 'middle',
                format: YFormat,
              }}
              enableGridX={!isColumn}
              enableGridY={!!isColumn}
              animate={false}
              enableLabel={false}
              markers={markers}
              // @ts-ignore
              tooltip={({
                indexValue,
                absY,
              }: BarTooltipProps<any> & { absY: number; absX: number }) => {
                const i = domainVals.findIndex((d) => d === indexValue);

                const x =
                  tooltipRef?.current?.parentElement?.style?.transform?.match(
                    /(-?[\d.]+)/g,
                  )?.[0];

                const hasX = x && +x <= width;

                const isRight = hasX && +(x as string) > width / 2;

                return (
                  <div
                    ref={tooltipRef}
                    className="p-crosshair"
                    style={{
                      transform: `translate(${isRight ? '-50%' : '50%'}, ${
                        absY < widgetHeight / 2 ? 'calc(100% + 35px)' : 0
                      })`,
                      visibility: hasX ? 'visible' : 'hidden',
                    }}
                  >
                    <div className="p-crosshair__div u-padding-20">
                      <div className="hover-header colorMonochromatic0 font-14">
                        {hoverHeaders[i]}
                      </div>
                      {hoverSeries.map((hover, ind) => {
                        const correspondingInd = dataSeries.findIndex(
                          ({ id }) => id === hover.corresponds_to,
                        );

                        const correspondingColor =
                          palette[
                            correspondingInd === -1 ? ind : correspondingInd
                          ];

                        return (
                          <CrossOverContent
                            key={`${hover.title}-LC-C`}
                            label={hover.labels?.[i] || hover.title}
                            value={hover.info[i]}
                            circleStyles={
                              correspondingColor
                                ? {
                                    backgroundColor: correspondingColor,
                                  }
                                : undefined
                            }
                          />
                        );
                      })}
                      {(data.pivot?.aggregationLabel ||
                        data.pivot?.aggregationFunction) &&
                        hoverSeries.length && (
                          <AggregationFooter
                            domainValsIndex={i}
                            aggregationFunction={data.pivot.aggregationFunction}
                            aggregationLabel={data.pivot.aggregationLabel}
                            hoverSeries={hoverSeries}
                          />
                        )}
                    </div>
                  </div>
                );
              }}
              layers={layers}
            />
          )}
        </div>
        <div ref={sliderContainerRef} />
        <div className="d-flex u-display-space-between align-items-start">
          <div>
            <GraphLegend
              legend={originalDataSeries}
              visibleIds={settingsVal?.[SETTINGS.VISIBLE_DATA]}
              assignColor={(i) => originalPalette[i]}
              setSelectedData={setSelectedData}
            />
          </div>
          <GraphStand stand={data.stand} />
        </div>
      </div>
    </ResizeSensor2>
  );
};

export default BarChart;
