import { FC } from 'react';
import { getLocalUserData } from '../../gql/user/local';
import { USER_ROLES } from '../../gql/user/types';
import { useQuery } from '@apollo/client';
import { LIVE_WIDGET_VERSION } from '../../gql/widget/queries';
import { GET_LIVE_QUERY } from '../../gql/query/queries';
import { useHistory } from 'react-router-dom';
import { WidgetsRoute, QueriesRoute } from '../../app/Routes';
import { getIsEditorPage } from '../../containers/Solutions/Structure/editing';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../IconButton';
import { ReactComponent as NewTabIcon } from '../../assets/icons/ui/l/new-tab.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/ui/l/alert.svg';
import { Popover } from '../Popover';
import { DropdownList } from '../DropdownList';
import { ENTITY_TYPES } from '../../gql/types';
import { Structure } from '../../gql/structure/types';
import { useAthenaQuery } from '../../gql/dataSource/hooks';
import { SpinnerWithCheckMark } from '../SpinnerWithCheckMark';

type Props = {
  widgetId: string;
  asInlineText?: boolean;
  structureData?: Structure;
  previewWithOrganization?: string;
  previewWithDataSource?: string;
};

type popoverProps = {
  asInlineText?: boolean;
  widgetVersionId: string;
  structureId: string;
  datasourceId: string;
  queryEntityId: string;
  widgetType: string;
  organizationId: string;
};

type visualComponentProps = {
  asInlineText?: boolean;
  enableOnClick?: boolean;
  widgetVersionId: string;
  structureId: string;
  datasourceId: string;
  organizationId: string;
};

type openInNewTabDropdownProps = {
  queryEntityId: string;
  structureId: string;
  datasourceId: string;
  widgetVersionId: string;
  organizationId: string;
};

const entityPaths = {
  [ENTITY_TYPES.WIDGET]: WidgetsRoute?.path,
  [ENTITY_TYPES.QUERY]: QueriesRoute?.path,
};

const PERMITTED_TO_EDIT_WIDGETS = [
  USER_ROLES.DATA_ANALYST,
  USER_ROLES.ANALYTICS_ENGINEER,
  USER_ROLES.CUSTOMER,
];

const PERMITTED_TO_EDIT_QUERIES = [USER_ROLES.DATA_ANALYST];

const generateEditWidgetURL = ({
  widgetVersionId,
  structureId,
  datasourceId,
  organizationId,
}: {
  widgetVersionId: string;
  structureId: string;
  datasourceId: string;
  organizationId: string;
}) =>
  widgetVersionId
    ? organizationId
      ? `${
          entityPaths[ENTITY_TYPES.WIDGET]
        }/${widgetVersionId}?organizationid=${organizationId}&datasourceid=${datasourceId}`
      : `${
          entityPaths[ENTITY_TYPES.WIDGET]
        }/${widgetVersionId}?structureid=${structureId}&datasourceid=${datasourceId}`
    : '';

const generateEditQueryURL = ({
  queryVersionId,
  structureId,
  datasourceId,
  organizationId,
}: {
  queryVersionId: string;
  structureId: string;
  datasourceId: string;
  organizationId: string;
}) =>
  queryVersionId
    ? organizationId
      ? `${
          entityPaths[ENTITY_TYPES.QUERY]
        }/${queryVersionId}?organizationid=${organizationId}&datasourceid=${datasourceId}`
      : `${
          entityPaths[ENTITY_TYPES.QUERY]
        }/${queryVersionId}?structureid=${structureId}&datasourceid=${datasourceId}`
    : '';

const OpenInNewTabDropDown: React.FC<openInNewTabDropdownProps> = (
  props: openInNewTabDropdownProps,
) => {
  const { t } = useTranslation();

  const id = props.queryEntityId;
  const { data, loading, error } = useAthenaQuery(GET_LIVE_QUERY, {
    variables: { id },
  });

  if (error) {
    return <IconButton ariaLabel={t('common.error')} icon={AlertIcon} />;
  }

  if (loading) {
    return <SpinnerWithCheckMark size={64} state="PENDING" />;
  }

  const queryVersionId = data?.['queryLiveVersion']?.versionId;

  const editQueryURL = generateEditQueryURL({
    queryVersionId,
    structureId: props.structureId,
    datasourceId: props.datasourceId,
    organizationId: props.organizationId,
  });
  const editWidgetURL = generateEditWidgetURL({
    widgetVersionId: props.widgetVersionId,
    structureId: props.structureId,
    datasourceId: props.datasourceId,
    organizationId: props.organizationId,
  });

  const listItems = [];

  listItems.push({
    key: 'Widget',
    label: t('entity.widget'),
    value: 'Widget',
  });

  listItems.push({
    key: 'Query',
    label: t('entity.query'),
    value: 'Query',
  });

  // FIXME the w-36 is of course uncool, need to find a cleaner solution
  return (
    <div className="w-36">
      <DropdownList
        items={listItems}
        onSelect={(key) => {
          switch (key) {
            case 'Widget': {
              window.open(editWidgetURL, '_blank');
              break;
            }
            case 'Query': {
              window.open(editQueryURL, '_blank');
              break;
            }
          }
        }}
      />
    </div>
  );
};

const VisualComponent: React.FC<visualComponentProps> = (
  props: visualComponentProps,
) => {
  const { t } = useTranslation();
  const editWidgetURL = generateEditWidgetURL({
    widgetVersionId: props.widgetVersionId,
    structureId: props.structureId,
    datasourceId: props.datasourceId,
    organizationId: props.organizationId,
  });

  // onClick should be disabled, when we show a popover, for example
  return props.asInlineText ? (
    <button
      aria-label="edit"
      title="Edit"
      onClick={
        !props.enableOnClick
          ? () => window.open(editWidgetURL, '_blank')
          : undefined
      }
      className="rounded-md text-blueberry-2 underline ring-focusBlue hover:text-blueberry-3 focus-visible:ring-2"
    >
      {t('error.widget.custom.edit')}
    </button>
  ) : (
    <IconButton
      ariaLabel={t('common.new-tab')}
      icon={NewTabIcon}
      dataId="edit-entity-btn"
      onClick={
        !props.enableOnClick
          ? () => window.open(editWidgetURL, '_blank')
          : undefined
      }
    />
  );
};

const PopoverWrapper: React.FC<popoverProps> = (props: popoverProps) => {
  const isAllowedToEditQuery = PERMITTED_TO_EDIT_QUERIES.includes(
    getLocalUserData()?.role as USER_ROLES,
  );

  // popover or straight edit?
  // first, text widgets don't have a query, so no popover
  // second, if queryEntityId is empty, means we couldn't read it from the config, so no reason to show the popover
  // finally, only showing this option to DAs for now, may enable it for AEs later
  const enableOnClick =
    isAllowedToEditQuery &&
    props.widgetType !== 'text' &&
    props.queryEntityId !== '';

  const visualComponent = (
    <VisualComponent
      enableOnClick={enableOnClick}
      asInlineText={props.asInlineText}
      widgetVersionId={props.widgetVersionId}
      structureId={props.structureId}
      datasourceId={props.datasourceId}
      organizationId={props.organizationId}
    />
  );

  return enableOnClick ? (
    <Popover
      on="click"
      content={
        <OpenInNewTabDropDown
          queryEntityId={props.queryEntityId}
          widgetVersionId={props.widgetVersionId}
          structureId={props.structureId}
          datasourceId={props.datasourceId}
          organizationId={props.organizationId}
        />
      }
    >
      {visualComponent}
    </Popover>
  ) : (
    <>{visualComponent}</>
  );
};

const EditEntityBtn: FC<Props> = ({
  widgetId,
  asInlineText,
  structureData,
  previewWithDataSource,
  previewWithOrganization,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  // FIXME looking at the URL is prone to error in case of changes in routing, but should work for now
  const structureId =
    structureData && structureData?.id
      ? structureData?.id
      : history.location.pathname.split('/')[
          history.location.pathname.split('/')[2] === 'edit' ? 3 : 2
        ];

  const datasourceId = previewWithDataSource
    ? previewWithDataSource
    : structureData && structureData?.['structureConfig']?.datasourceId
    ? structureData?.['structureConfig']?.datasourceId
    : '';

  const isAllowedToEditWidget = PERMITTED_TO_EDIT_WIDGETS.includes(
    getLocalUserData()?.role as USER_ROLES,
  );

  const id = widgetId;
  const { data, loading, error } = useQuery(LIVE_WIDGET_VERSION, {
    variables: { id },
  });

  if (error) {
    return <IconButton ariaLabel={t('common.error')} icon={AlertIcon} />;
  }

  if (loading) {
    return <SpinnerWithCheckMark size={64} state="PENDING" />;
  }

  const widgetVersionId = data?.['widgetLiveVersion']?.versionId;
  const widgetType = data?.['widgetLiveVersion']['widgetConfig']?.type;
  const queryEntityId =
    widgetType === 'text'
      ? ''
      : data?.['widgetLiveVersion']['widgetConfig']?.queryId;

  if (!isAllowedToEditWidget || getIsEditorPage(widgetId)) {
    return null;
  }

  return (
    <PopoverWrapper
      asInlineText={asInlineText}
      widgetVersionId={widgetVersionId}
      structureId={structureId}
      datasourceId={datasourceId}
      organizationId={previewWithOrganization ?? ''}
      queryEntityId={queryEntityId}
      widgetType={widgetType}
    />
  );
};

export default EditEntityBtn;
